import {Box, Container } from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Column} from '@material-table/core';
import {BrixTable} from '@admin/components/common';
import { IPage, AddingStyleHeader} from '@brix/shared-frontend';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';

const columns: Column<any>[] = [
  {
    title: 'Activity',
    field: 'activity',
  },
  {
    title: 'Property/User changed',
    field: 'itemChanged',
    type: 'string',
  },
  {
    title: 'Actions changed',
    field: 'actionsChanged',
    type: 'string',
  },
  {
    title: 'Date',
    field: 'updatedAt',
    type: 'string',
  },
  {
    title: 'Time',
    field: 'updatedAt',
    type: 'string',
  },
  {
    title: 'IP Address',
    field: 'ipAddress',
    type: 'string',
  },
];


const LogsActivitiesDetails: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    document.title = 'Logs Activities Details';
  }, []);


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <BrixTable
            endpoint="/"
            columns={AddingStyleHeader(columns)}
            title="Logs Activities Details"
            hasToRefetch={refetch}
            setRefetch={setRefetch}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default LogsActivitiesDetails;
