import {api} from '@admin/utils';
import {errorHandler} from '@brix/shared-frontend';
import {Box, Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Dispatch, SetStateAction} from 'react';
import {toast} from 'react-toastify';

type DocumentDialogParams = {
  userId: string;
  open: boolean;
  documents: any;
  onClose: Dispatch<SetStateAction<boolean>>;
};
export const DocumentDailog = ({userId, open, documents, onClose}: DocumentDialogParams) => {
  const documentAction = async (action: boolean) => {
    try {
      const path = action ? 'approve' : 'reject';
      await api['users'].post(`/${userId}/profile/${path}-document`);
      toast.success(`Document ${action ? 'approved' : 'rejected'} successfully`);
      onClose(false);
    } catch (e: any) {
      toast.error(errorHandler(e.data));
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Review Investor Documents</DialogTitle>
      <DialogContent>
        {documents &&
        documents.map((document: any) => {
          return (
            <Box sx={{pb: 2}} key={document.id}>
              <CardMedia component="img" image={document?.location}/>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={() => documentAction(false)}>
          Reject
        </Button>
        <Button color="primary" variant="outlined" onClick={() => documentAction(true)}>
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};
