import useAuth from '@admin/hooks/useAuth';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router-dom';

const AuthGuard = ({children}: {children: React.ReactElement}) => {
  const {isAuthenticated} = useAuth();
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
