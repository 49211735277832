import {Box, Container, Link} from '@mui/material';
import React, {useEffect} from 'react';
import {Column} from '@material-table/core';
import moment from 'moment';
import {IPage, AddingStyleHeader} from '@brix/shared-frontend';
import {BrixTable} from '@admin/components/common';
import {useHistory} from 'react-router';

const columns: Column<any>[] = [
  {
    title: 'Name',
    field: 'name',
    type: 'string',
    customSort: (a, b) => a.name.toLowerCase() - b.name.toLowerCase(),
  },
  {
    title: 'Email',
    field: 'email',
    type: 'string',
    customSort: (a, b) => a.email.toLowerCase() - b.email.toLowerCase(),
    editable: 'never',
    render: (data) => {
      return (
        <Link style={{color: 'inherit'}} href={`mailto:${data.email}`}>
          {data.email}
        </Link>
      );
    },
  },
  {
    title: 'Phone',
    field: 'phone',
    type: 'string',
  },
  {
    title: 'Country',
    field: 'country.name',
    type: 'string',
    customFilterAndSearch: () => true,
    render: (data) => data.__country__ && <span>{data.__country__?.name}</span>,
  },
  {
    title: 'Company',
    field: 'company',
    customSort: (a, b) => a.company.toLowerCase() - b.company.toLowerCase(),
    type: 'string',
  },
  {
    title: 'Submission Date',
    field: 'createdAt',
    type: 'string',
    render: (data) => <span>{moment(data.createdAt).format('MM-DD-YYYY')}</span>,
  },
];

const ContactUsListView: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = 'Contact Request List';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{mb: 4}}>
          <BrixTable
            title="Contact Request List"
            endpoint="contact-requests"
            includes="country"
            isRowDelete={true}
            options={{
              search: true
            }}
            columns={AddingStyleHeader(columns)}
            onRowClick={(_, rowData) => history.push(`/contact-requests/${rowData?.id}`, rowData)}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default ContactUsListView;
