import {StrictMode} from 'react';
import * as ReactDOM from 'react-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import App from './app/app';
import {LocaleProvider} from '@admin/contexts/LocaleContext';

ReactDOM.render(
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LocaleProvider>
        <App/>
      </LocaleProvider>
    </LocalizationProvider>
  </StrictMode>,
  document.getElementById('root')
);
