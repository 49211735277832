import {Link as RouterLink, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from '@admin/hooks/useAuth';
import {Cog as CogIcon} from '@admin/icons/Cog';
import {UserCircle as UserCircleIcon} from '@admin/icons/UserCircle';

interface AccountPopoverProps {
  anchorEl: Element;
  onClose: VoidFunction;
  open: boolean;
  other: [];
}

export const AccountPopover = ({anchorEl, onClose, open, ...other}: AccountPopoverProps) => {
  const {logout, user} = useAuth();

  const history = useHistory();

  const handleLogout = async () => {
    try {
      onClose?.();
      await logout(user?.email || '');
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{sx: {width: 300}}}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <Avatar
          src={user?.avatarURL}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">{user?.firstName}</Typography>
          <Typography color="textSecondary" variant="body2">
            BRiX Inc.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{my: 1}}>
        <MenuItem onClick={onClose} component={RouterLink} to="/dashboard/settings">
          <ListItemIcon>
            <CogIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Settings</Typography>} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
