import {useEffect, useState} from 'react';
import {Box, Card, CardContent, Grid, Switch, Typography} from '@mui/material';
import {useSettings} from '@brix/shared-frontend';
import { ChangePasswordForm } from './ChangePassword';

const getValues = (settings: {direction: string; responsiveFontSizes: boolean; theme: string}) => ({
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  theme: settings.theme,
});

export const GeneralSettings = () => {
  const {settings, saveSettings} = useSettings();
  const [values, setValues] = useState(getValues(settings));
  const [value, setValue] = useState(settings.theme === 'dark' ? true : false);

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleChange = (e: any) => {
    setValues({
      ...values,
      theme: e.target.checked ? 'dark' : 'light',
    });
    setValue(e.target.checked);
    saveSettings({
      direction: values.direction,
      responsiveFontSizes: values.responsiveFontSizes,
      theme: e.target.checked ? 'dark' : 'light',
    });
  };

  return (
    <Box sx={{mt: 4, height: '100vh'}}>
      <Card sx={{mt: 4}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Interface</Typography>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <Typography variant="subtitle1">Dark Theme</Typography>
                <Switch value={value} checked={value} onChange={handleChange} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ChangePasswordForm />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
