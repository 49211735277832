import {Box, Button, Card, CardContent, CircularProgress, Grid, MenuItem, TextField, Typography,} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {api, loadStates} from '@admin/utils';
import {toast} from 'react-toastify';
import {errorHandler, setMultipleValues, useYupValidationResolver,} from '@brix/shared-frontend';
import {IAddress} from '../../interfaces';
import {useMutation, useQuery, useQueryClient} from 'react-query';

const validationSchema = Yup.object({
  street: Yup.string().required('This field is required'),
  street2: Yup.string(),
  city: Yup.string().required('This field is required'),
  zipCode: Yup.string().required('This field is required'),
  stateCode: Yup.string().required('This field is required'),
  countryId: Yup.string().required('This field is required'),
});

const updateAddress = async (companyId: string, address: IAddress) => {
  const {data} = await api['companies'].patch(`/${companyId}/address`, address);
  return data;
};

const getAddress = async (companyId: string) => {
  const fields = 'street,street2,city,zipCode,stateCode,countryId';
  const {data} = await api['companies'].get(`/${companyId}/address?fields=${fields}`);
  return data;
};

export const CompanyAddress = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const {state} = useLocation<any>();
  const queryClient = useQueryClient();
  const [countryCode] = useState<string>('USA');

  const {register, handleSubmit, formState, setValue} = useForm<IAddress>({resolver, mode: 'onChange'});
  const {errors} = formState;

  const {data: stateList} = useQuery(['states', {countryCode}], () => loadStates(countryCode));

  const {data: addressData, isLoading: isAddressLoading, refetch} = useQuery<any, any>(
    ['companies', {companyId: state?.id}],
    () => getAddress(state.id),
    {
      onSuccess: (address: any) => {
        setValue('countryId', countryCode);
        setMultipleValues(setValue, address[0]);
      },
      onError: (err: any) => {
        toast.error(errorHandler(err.data));
      },
    }
  );

  useEffect(() => {
    if (addressData) {
      setMultipleValues(setValue, addressData[0]);
      setValue('countryId', countryCode);
    }
  }, [addressData]);

  const mutationUpdate = useMutation((data: IAddress) => updateAddress(state.id, data), {
    onSuccess: async () => {
      toast.success('Address updated successfully');
      await queryClient.resetQueries(['address', {companyId: state.id}]);
      await refetch();
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const onSaveAddress = (address: IAddress) => {
    mutationUpdate.mutate(address);
  };

  return (
    <form onSubmit={handleSubmit(onSaveAddress)}>
      <Card>
        <CardContent>
          {!isAddressLoading ? (
            <React.Fragment>
              <Box>
                <Typography variant="h6">Address</Typography>
              </Box>
              <Grid item>
                <TextField
                  error={Boolean(errors?.street?.message)}
                  fullWidth
                  helperText={errors.street?.message}
                  defaultValue=" "
                  label="Address 1"
                  sx={{mt: 2}}
                  {...register('street')}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={Boolean(errors.street2?.message)}
                  fullWidth
                  helperText={errors.street2?.message}
                  label="Address 2 (optional)"
                  sx={{mt: 2}}
                  {...register('street2')}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={Boolean(errors.city?.message)}
                  fullWidth
                  helperText={errors.city?.message}
                  label="City"
                  sx={{mt: 2}}
                  {...register('city')}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="State"
                  select
                  id="stateCode"
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                  error={Boolean(errors.stateCode?.message)}
                  {...register('stateCode')}
                  defaultValue={addressData[0]?.stateCode}
                >
                  {stateList ? stateList.map(({stateCode, name}, idx) => (
                      <MenuItem key={idx} value={stateCode}>
                        {name}
                      </MenuItem>
                    )) :
                    <MenuItem>Loading...</MenuItem>}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Country"
                  select
                  id="country"
                  fullWidth
                  defaultValue={countryCode}
                  sx={{
                    mt: 2,
                  }}
                  error={Boolean(errors.countryId?.message)}
                  {...register('countryId')}
                >
                  <MenuItem value={countryCode}>
                    United States
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  error={Boolean(errors.zipCode?.message)}
                  fullWidth
                  helperText={errors.zipCode?.message}
                  label="Zip code"
                  sx={{mt: 2}}
                  {...register('zipCode')}
                />
              </Grid>
              <Grid container justifyContent="flex-end" spacing={3} pt={4}>
                <Grid item>
                  <Button type="submit" disabled={formState.isSubmitting} variant="outlined">
                    Save Address
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Box justifyContent="center" display="flex">
              <CircularProgress/>
            </Box>
          )}
        </CardContent>
      </Card>
    </form>
  );
};
