import {AppBar, Box, Card, CardContent, Container, Toolbar, Typography} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {useLocation} from 'react-router';
import {useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";

const Tabs = (props: any) => {
  const {sections, emitChange, propertyName} = props;
  const [tabSelected, setTabSelected] = useState(1);
  const {state} = useLocation<any>();


  useEffect(() => {
    emitChange(tabSelected);
  }, [tabSelected]);

  const updatedTab = (value: any) => {
    setTabSelected(value);
  };

  return (
    <Card
      sx={{
        height: '60px',
        p: 0,
        m: 0,
        mb: 4,
      }}
    >
      <CardContent
        sx={{
          p: 0,
          m: 0,
          ml: 4,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumbs
          sx={{
            p: 0,
            m: 0,
            ml: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 600,
            fontSize: '18px',
          }}
          aria-label="breadcrumb"
          separator="›"
        >
          <Typography
            sx={{
              '&:hover': {opacity: 0.7},
              transition: '0.2s ease-in-out',
              underline: 'hover',
              color: 'inherit',
              fontWeight: 600,
              cursor: 'pointer',
              fontSize: '18px',
            }}
          >
            <NavLink to="/companies" style={{ textDecoration: 'none', color: 'inherit' }}>Companies</NavLink>
          </Typography>
          <Typography
            sx={{
              transition: '0.2s ease-in-out',
              underline: 'hover',
              color: 'inherit',
              fontWeight: 600,
              fontSize: '18px',
            }}
          >
            {propertyName}
          </Typography>
          <Typography
            sx={{
              transition: '0.2s ease-in-out',
              underline: 'hover',
              color: 'inherit',
              fontWeight: 600,
              fontSize: '18px',
            }}
          >
            {tabSelected === 1
              ? 'Address'
              : 'Properties'}
          </Typography>
        </Breadcrumbs>
        <Toolbar
          disableGutters
          sx={{
            ml: {
              lg: 6,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            {sections.map((section: any) => (
              <Typography
                sx={{
                  '&:hover': {opacity: 0.7},
                  transition: '0.2s ease-in-out',
                  mr: 6,
                  color: `${tabSelected === section.value ? '#cc4645' : ''}`,
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
                onClick={() => {
                  updatedTab(section.value);
                }}
                key={section.value}
              >
                {section.title}
              </Typography>
            ))}
          </Box>
        </Toolbar>
      </CardContent>
    </Card>
  );
};

export default Tabs;
