import React, {forwardRef} from 'react';
import {Control, Controller, FieldError} from 'react-hook-form';
import {NumericFormat} from 'react-number-format';
import {TextField} from '@mui/material';

interface SelectArgs<T> {
  name: string;
  prefix?: string;
  suffix?: string;
  label?: string;
  disabled?: boolean;
  control: Control<any>;
  error?: { message: string } | FieldError;
}

const NumericField = forwardRef(<T, >({control, name, error, prefix, suffix, label, disabled}: SelectArgs<T>) => {
  return (
    <Controller name={name}
                control={control}
                render={({field: {onChange, name, value}}) => {
                  return (
                    <NumericFormat customInput={TextField} thousandSeparator="," decimalSeparator="." suffix={suffix}
                                   allowNegative={false} error={Boolean(error)} fullWidth sx={{mt: 2}} value={value}
                                   helperText={error?.message} decimalScale={2} disabled={disabled}
                                   prefix={Number(value) < 0 ? `-${prefix ?? ''}` : prefix}
                                   label={label} name={name} onValueChange={event => onChange(event.floatValue)}/>
                  );
                }}/>
  );
});

export default NumericField;
