import { Box, Container, Divider, List, Card, CardHeader } from '@mui/material';
import { useLocation } from 'react-router';
import { PropertyListItem } from '@admin/components/common';
import { PropertyTypes, IPage, formatWithDigits } from '@brix/shared-frontend';
import { booleanToString } from '@admin/utils';
import DateFnsAdapter from '@date-io/date-fns';

const dateFns = new DateFnsAdapter();

const ContributingPropertyDetailView: React.FunctionComponent<IPage> = () => {
  const { state } = useLocation<any>();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
        color: 'text.primary',
        fontFamily: 'inherit',
      }}
    >
      <Container maxWidth="xl">
        <Card>
        <CardHeader title="Fractionalize Properties Details" />
          <Divider />
          <List disablePadding>
            <PropertyListItem divider label="Owner's name" value={state.name} />
            <PropertyListItem divider label="Email" value={state.email} />
            <PropertyListItem divider label="Phone Number" value={state.phone} />
            <PropertyListItem divider label="Property Address" value={state.addressStreet} />
            <PropertyListItem divider label="Property Address 2" value={state.addressStreet2} />
            <PropertyListItem divider label="Zip code" value={state.addressZip} />
            <PropertyListItem divider label="City" value={state.addressCity} />
            <PropertyListItem divider label="State" value={state.addressState} />
            <PropertyListItem divider label="Country" value={state.addressCountry} />
            <PropertyListItem divider label="Year Built" value={state.yearBuilt} />
            <PropertyListItem divider label="Number of Beds" value={state.numberOfBedrooms} />
            <PropertyListItem divider label="Number of Bathrooms" value={state.numberOfBathrooms} />
            <PropertyListItem divider label="Garage" value={booleanToString(state.hasGarage)} />
            <PropertyListItem divider label="Washer & Dryer" value={booleanToString(state.hasWahserAndDryer)} />
            <PropertyListItem divider label="Swimming Pool" value={booleanToString(state.hasSwimmingPool)} />
            <PropertyListItem divider label="Gym" value={booleanToString(state.hasGym)} />
            <PropertyListItem divider label="Clubhouse" value={booleanToString(state.hasClubhouse)} />
            <PropertyListItem divider label="Property type" value={PropertyTypes[state.propertyType]} />
            <PropertyListItem divider label="Is property rented?" value={booleanToString(state.isPropertyRented)} />
            <PropertyListItem divider label="Is There a Lien on the Property?" value={booleanToString(state.lienProperty)} />
            <PropertyListItem divider label="Monthly payment" value={formatWithDigits(state.monthlyPayment)} />
            <PropertyListItem divider label="How long current renter" value={state.timeCurrentRenter} />
            <PropertyListItem
              divider
              label="Rental expiration date"
              value={state.rentalExpirationDate && dateFns.format(new Date(state.rentalExpirationDate), 'fullDate')}
            />
            <PropertyListItem divider label="Any rental restrictions? &nbsp;&nbsp;" value={state.rentalRestrictions} />
            <PropertyListItem
              divider
              label="Do they own the property outright?&nbsp;&nbsp;"
              value={booleanToString(state.propertyIsOwnedOutright)}
            />
            <PropertyListItem divider label="Name of persons on the deed?&nbsp;&nbsp;" value={state.personsOnDeed} />
            <PropertyListItem divider label="Property under" value={state.propertyOwnerType} />
            <PropertyListItem divider label="Is there a mortgage?" value={booleanToString(state.hasMortgage)} />
            <PropertyListItem divider label="How many mortgages?" value={state.numberOfMortgages} />
            <PropertyListItem divider label="Amount owned" value={formatWithDigits(state.amountOwne)} />
            <PropertyListItem divider label="Is there a HOA?" value={booleanToString(state.hasHoa)} />
            <PropertyListItem divider label="Cable TV" value={booleanToString(state.cableTV)} />
            <PropertyListItem divider label="Internet" value={booleanToString(state.internet)} />
            <PropertyListItem divider label="Water" value={booleanToString(state.water)} />
            <PropertyListItem divider label="Building Insurance" value={booleanToString(state.buildingInsurance)} />
            <PropertyListItem divider label="Trash Collection" value={booleanToString(state.trashCollection)} />
            <PropertyListItem divider label="Landscaping / Lawn Maintenance" value={booleanToString(state.landscapingLawnMaintenance)} />
            <PropertyListItem divider label="Security Guard / Gatehouse" value={booleanToString(state.securityGuardGatehouse)} />
            <PropertyListItem divider label="Community Swimming Pool" value={booleanToString(state.communitySwimmingPool)} />
            <PropertyListItem divider label="Community Tennis Court" value={booleanToString(state.communityTennisCourt)} />
            <PropertyListItem divider label="Community Basketball Court" value={booleanToString(state.communityBasketballCourt)} />
            <PropertyListItem divider label="Picnic / BBQ Area" value={booleanToString(state.picnicArea)} />
            <PropertyListItem divider label="Flood Insurance" value={booleanToString(state.hasFloodInsurance)} />
            <PropertyListItem divider label="Wind Insurance" value={booleanToString(state.hasWindInsurance)} />
            <PropertyListItem
              divider
              label="Homeowner Insurance"
              value={booleanToString(state.hasHomeownerInsurance)}
            />
            <PropertyListItem
              divider
              label="What appliances are included?&nbsp;&nbsp;"
              value={state.appliancesIncluded}
            />
            <PropertyListItem divider label="Age of appliances" value={state.ageOfAppliances} />
            <PropertyListItem divider label="Pets allowed?" value={booleanToString(state.arePetsAllowed)} />
            <PropertyListItem divider label="Pet fees" value={formatWithDigits(state.petFees)} />
            <PropertyListItem divider label="Over 55+ community" value={booleanToString(state.isSeniorCommunity)} />
            <PropertyListItem divider label="Rental hold period" value={state.rentalHoldPeriod} />
          </List>
        </Card>
      </Container>
    </Box>
  );
};

export default ContributingPropertyDetailView;
