import {PropertyListItem} from '@admin/components/common';
import {useForm} from 'react-hook-form';
import {Card, Divider, List, ListItem, ListItemText, Typography, Button, Box} from '@mui/material';
import {CardHeader} from '@admin/components/common';
import {useState} from 'react';
import * as yup from 'yup';
import {useYupValidationResolver, responseHandler, errorHandler, DocumentTypes} from '@brix/shared-frontend';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';
import {api, getCountryName, loadCountries, transformSelect} from '@admin/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useQuery} from 'react-query';
import {IProfile} from '../interfaces';
import {DocumentDailog} from './Document';

const validationSchema = yup.object({
  citizenship: yup.string().required('This field is required'),
  dob: yup.string(),
  phone: yup.string().min(6).max(16).required('This field is required'),
});

const loadProfile = async (userId: string) => {
  const {data} = await api['users'].get(`/${userId}/profile`);
  return data;
};

const loadDocuments = async (userId: string) => {
  const {data} = await api['users'].get(`/${userId}/documents?filter=type||$eq||${DocumentTypes.identity}&limit=3`);
  return data.data;
};

export const ProfileInfo = ({userId}: {userId: string}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const [isEditing, setIsEditing] = useState(false);
  const [isDocumentOpen, setIsDocumentOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<IProfile>({resolver, mode: 'onChange'});

  const {data: countries} = useQuery('countries', loadCountries, {keepPreviousData: true});
  const {data: profile, refetch} = useQuery<IProfile, any>(['userId', {userId}, 'profile'], () => loadProfile(userId), {
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const {data: documents} = useQuery<any, any>(['userId', {userId}, 'document'], () => loadDocuments(userId), {
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const onSubmit = async (data: IProfile) => {
    try {
      const res = await api['users'].patch(`/${userId}/profile`, {
        ...data,
      });
      refetch();
      toast.success(responseHandler(res.data));
    } catch (e: any) {
      toast.error(errorHandler(e.data));
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          title="Profile"
          isEditing={isEditing}
          showButton={Boolean(profile)}
          onClick={() => setIsEditing(!isEditing)}
          disabled={Boolean(Object.keys(errors).length)}
        />
        <Divider />
        {profile && (
          <List disablePadding>
            <PropertyListItem
              name="citizenship"
              itemType="select"
              align={'left'}
              divider
              label="Citizenship"
              value={isEditing ? profile?.citizenship : getCountryName(countries, profile?.citizenship)}
              control={control}
              isEdit={isEditing}
              selectProps={{
                options: transformSelect(countries, Boolean(!profile?.citizenship)),
              }}
              error={errors?.citizenship?.message}
            />
            <PropertyListItem
              name="dob"
              itemType="input"
              align={'left'}
              divider
              label="Date of Birth"
              value={profile.dob ? dayjs(new Date(profile.dob)).format('YYYY-MM-DD') : ''}
              control={control}
              isEdit={isEditing}
              inputProps={{
                type: 'date',
                placeholder: 'Please enter date of birth',
              }}
              error={errors?.dob?.message}
            />
            <PropertyListItem
              name="phone"
              itemType="input"
              align={'left'}
              divider
              label="Phone Number"
              value={profile.phone}
              control={control}
              isEdit={isEditing}
              inputProps={{
                type: 'text',
                placeholder: 'Enter phone number',
              }}
              error={errors?.phone?.message}
            />
            <PropertyListItem
                name="citizenshipStatus"
                itemType="select"
                align={'left'}
                divider
                label="Citizenship Status"
                value={profile.citizenshipStatus}
                control={control}
                isEdit={isEditing}
                selectProps={{
                  options: [
                    {
                      label: 'US Citizen',
                      value: 'US Citizen',
                    },
                    {
                      label: 'Non-US Citizen',
                      value: 'Non-US Citizen',
                    },
                    {
                      label: 'US-Visa Holder',
                      value: 'US-Visa Holder',
                    },
                    {
                      label: 'LPR',
                      value: 'LPR',
                    },
                  ],
                }}
                error={errors?.citizenshipStatus?.message}
              />
            <Divider />
            <ListItem sx={{px: 3, py: 1.5, alignItems: 'center'}}>
              <ListItemText
                primary={
                  <Typography
                    sx={{minWidth: 180, display: 'flex', alignItems: 'center'}}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    User Document
                  </Typography>
                }
                secondaryTypographyProps={{display: 'flex', alignItems: 'center'}}
                secondary={
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (documents && documents.length) {
                          setIsDocumentOpen(true);
                        } else {
                          toast.info('Investor has not upload any document');
                        }
                      }}
                    >
                      Show document
                    </Button>
                    {profile.isDocumentVerified && (
                      <Box component={'span'} sx={{pl: 2, pr: 1, display: 'flex', alignItems: 'center'}}>
                        <CheckCircleIcon color="success" />
                        Approved
                      </Box>
                    )}
                  </>
                }
                sx={{display: 'flex', flexDirection: 'row', my: 0}}
              />
            </ListItem>
          </List>
        )}
      </form>
      <DocumentDailog userId={userId} open={isDocumentOpen} onClose={setIsDocumentOpen} documents={documents} />
    </Card>
  );
};
