import { Box, Button, Card, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '@admin/utils/api';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useYupValidationResolver, IChangePassword, errorHandler } from '@brix/shared-frontend';
import 'react-phone-input-2/lib/style.css';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const updatePassword = async (bodyPassword: IChangePassword) => {
  const res = await api['auth'].post(`/change-password`, bodyPassword);
  return res.data;
};

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('This field is required'),
  newPassword: Yup.string().required().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
  "New Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
),
  passwordConfirm: Yup.string().label('confirm password').required().oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
});

export const ChangePasswordForm = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const [isOpenModal, setIsOpenModal] = useState(false)

  const queryClient = useQueryClient();

  const { register, handleSubmit, formState, setValue, reset } = useForm<IChangePassword>({
    resolver,
    mode: 'onChange',
  });
  const { errors } = formState;

  const mutation = useMutation((data: IChangePassword) => updatePassword(data), {
    onSuccess: () => {
      toast.success('Password has changed successfully');
      queryClient.resetQueries('auth');
      reset();
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const onSaveProperty = (bodyPassword: IChangePassword) => {
    mutation.mutate(bodyPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSaveProperty)}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Change Password</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(errors.oldPassword?.message)}
                    fullWidth
                    helperText={errors.oldPassword?.message}
                    label="Old Password"
                    type="password"
                    sx={{ mt: 2 }}
                    {...register('oldPassword')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(errors.newPassword?.message)}
                    fullWidth
                    helperText={errors.newPassword?.message}
                    label="New Password"
                    type="password"
                    sx={{ mt: 2 }}
                    {...register('newPassword')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(errors.passwordConfirm?.message)}
                    fullWidth
                    helperText={errors.passwordConfirm?.message}
                    label="Confirm New Password"
                    type="password"
                    sx={{ mt: 2 }}
                    {...register('passwordConfirm')}
                  />
                </Grid>
              </Grid>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2,
              }}>
                <Button type="submit" disabled={formState.isSubmitting} variant="outlined" autoFocus>
                  Save Password
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
    </form>
  )
};
