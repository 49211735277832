import { Box, Container, Grid, Divider, List, Card, CardHeader } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { IPage, IContactUs } from '@brix/shared-frontend';
import { PropertyListItem } from '@admin/components/common';

const ContactUsDetailsView: React.FunctionComponent<IPage> = () => {

    const { state } = useLocation<IContactUs>();

    useEffect(() => {
        document.title = 'Contact Us';
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 3,
                backgroundColor: 'background.default',
                height: '100%',
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="xl">
                <Card>
                    <CardHeader title="Contact Request Details" />
                    <Divider />
                    <List disablePadding>
                        <PropertyListItem divider label="Name" value={state.name} />
                        <PropertyListItem divider label="Email" value={state.email} />
                        <PropertyListItem divider label="Phone Number" value={state.phone} />
                        <PropertyListItem divider label="Company" value={state.company} />
                        <PropertyListItem divider label="Message" value={state.message} />
                    </List>
                </Card>
            </Container>
        </Box>
    );
};

export default ContactUsDetailsView;
