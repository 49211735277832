import {IRoute} from '@brix/shared-frontend';
import LoginView from './views/auth/LoginView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import DashboardView from './views/dashboard';
import UserListView from './views/dashboard/users';
import SettingsView from './views/dashboard/settings';
import PropertiesListView from './views/dashboard/properties';
import CreatePropertyView from './views/dashboard/properties/create';
import CompanyList from './views/dashboard/company';
import {DashboardLayout} from './layouts/DashboardLayout/DashboardLayout';
import {Fragment} from 'react';
import {RouteComponentProps, Route, Switch} from 'react-router-dom';
import GuestGuard from '@admin/components/guards/GuestGuard';
import AuthGuard from '@admin/components/guards/AuthGuard';
import ContactUsListView from './views/dashboard/contact-us';
import UserDetailView from './views/dashboard/users/[userId]';
import SecurityQuestionView from './views/dashboard/security-question';
import PropertiesDetailsView from './views/dashboard/properties/[propertyId]';
import ContributingPropertyDetailView from './views/dashboard/contributing-properties/[contributingPropertyId]';
import ContributingPropertiesListView from './views/dashboard/contributing-properties';
import CompanyDetailsView from './views/dashboard/company/[companyId]';
import ManagementFees from './views/dashboard/management-fees';
import ContactUsDetailsView from './views/dashboard/contact-us/[contactUsId]';
import PropertyDetailsView from './views/dashboard/properties/[propertyId]/components/PropertyDetailsView';
import LogsActivitiesList from './views/dashboard/logs';
import LogsActivitiesDetails from './views/dashboard/logs/[logId]'

export const renderRoutes = (routes: IRoute[] = []) => {
  return (
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={(props: RouteComponentProps<any>) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <route.component name={route.name} {...props} {...route.props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  );
};

const routes: IRoute[] = [
  {
    path: '/login',
    name: 'Login',
    guard: GuestGuard,
    exact: true,
    component: LoginView,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    exact: true,
    component: ForgotPasswordView,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    exact: true,
    component: ResetPasswordView,
  },
  {
    path: '/',
    name: 'Dashboard',
    guard: AuthGuard,
    component: DashboardView,
    layout: DashboardLayout,
    routes: [
      {
        path: '/',
        name: 'Overview',
        exact: true,
        component: DashboardView,
      },
      {
        path: '/dashboard',
        name: 'Overview',
        exact: true,
        component: DashboardView,
      },
      {
        path: '/dashboard/settings',
        name: 'Settings',
        exact: true,
        component: SettingsView,
      },
      {
        path: '/users',
        name: 'Users',
        exact: true,
        component: UserListView,
      },
      {
        path: '/companies',
        name: 'Companies',
        exact: true,
        component: CompanyList,
      },
      {
        exact: true,
        name: 'Company Details',
        path: '/companies/:id',
        component: CompanyDetailsView,
      },
      {
        path: '/properties',
        name: 'Properties',
        exact: true,
        component: PropertiesListView,
      },
      {
        exact: true,
        name: 'Create Property',
        path: '/properties/create',
        component: CreatePropertyView,
      },
      {
        exact: true,
        name: 'Edit Property',
        path: '/properties/:id',
        component: PropertiesDetailsView,
      },
      {
        exact: true,
        name: 'Management Fees',
        path: '/management-fees',
        component: ManagementFees,
      },
      {
        path: '/users/:id',
        name: 'Users',
        exact: true,
        component: UserDetailView,
      },
      {
        path: '/contact-requests',
        name: 'Contact Requests',
        exact: true,
        component: ContactUsListView,
      },
      {
        path: '/contact-requests/:id',
        name: 'Contact Requests',
        exact: true,
        component: ContactUsDetailsView,
      },
      {
        path: '/preview/:id',
        name: 'Property Preview',
        exact: true,
        component: PropertyDetailsView,
      },
      {
        path: '/fractionalize-properties',
        name: 'Fractionalize Properties',
        exact: true,
        component: ContributingPropertiesListView,
      },
      {
        path: '/fractionalize-properties/:id',
        name: 'Fractionalize Properties',
        exact: true,
        component: ContributingPropertyDetailView,
      },
      {
        path: '/security-questions',
        name: 'Security Questions',
        exact: true,
        component: SecurityQuestionView,
      },
      {
        path: '/logs-activities',
        name: 'Logs Activities',
        exact: true,
        component: LogsActivitiesList,
      },
      {
        path: '/logs-activities/:id',
        name: 'Logs Activities',
        exact: true,
        component: LogsActivitiesDetails,
      },
    ],
  },
];

export default routes;
