import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router-dom';
import useAuth from '@admin/hooks/useAuth';

const GuestGuard = ({children}: {children: React.ReactElement}) => {
  const {isAuthenticated} = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
