import {useMemo, useRef, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Divider, Drawer, Typography, useMediaQuery} from '@mui/material';

import Logo from '@admin/components/Logo';
import {Home as HomeIcon} from '@admin/icons/Home';
import {Users as UsersIcon} from '@admin/icons/Users';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShieldIcon from '@mui/icons-material/Shield';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {Scrollbar} from '@admin/components/Scrollbar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {DashboardSidebarSection} from './DashboardSidebarSection';
import AssignmentIcon from '@mui/icons-material/Assignment';

const getSections = () => [
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <HomeIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Users',
        path: '/users',
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Companies',
        path: '/companies',
        icon: <BusinessIcon />,
      },
      {
        title: 'Properties',
        path: '/properties',
        icon: <ApartmentIcon />,
        children: [
          {
            title: 'List',
            path: '/properties',
          },
          {
            title: 'Create',
            path: '/properties/create',
          },
        ],
      },
      {
        title: 'Management Fees',
        path: '/management-fees',
        icon: <AccountBalanceWalletIcon />,
      },
      {
        title: 'Contact Requests',
        path: '/contact-requests',
        icon: <ContactPageIcon fontSize="small" />,
      },
      {
        title: 'Fractionalize Properties',
        path: '/fractionalize-properties',
        icon: <CorporateFareIcon fontSize="small" />,
      },
      {
        title: 'Security Questions',
        path: '/security-questions',
        icon: <ShieldIcon fontSize="small" />,
      },
      {
        title: 'Logs Activities',
        path: '/logs-activities',
        icon: <AssignmentIcon fontSize="small" />
      },
    ],
  },
];

export const DashboardSidebar = (props) => {
  const location = useLocation();
  const {onClose, open} = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const sections = useMemo(() => getSections(), []);
  const organizationsRef = useRef(null);

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: 'background.paper',
        }}
      >
        <div>
          <Box sx={{p: 3}}>
            <Logo width={80} height={80} />
          </Box>
          <Box sx={{px: 2}}>
            <Box
              ref={organizationsRef}
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1,
              }}
            >
              <div>
                <Typography color="inherit" variant="subtitle1">
                  BRiX
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3,
          }}
        />
        <Box sx={{flexGrow: 1}}>
          {sections.map((section) => (
            <DashboardSidebarSection
              key={section.title}
              path={location.pathname}
              sx={{
                mt: 2,
                '& + &': {
                  mt: 2,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748', // dark divider
          }}
        />
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) =>
              theme.palette.mode === 'dark' ? 1 : 0,
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{zIndex: (theme) => theme.zIndex.appBar + 100}}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
