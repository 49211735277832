import {useEffect, useState} from 'react';
import {useLocaleContext} from '@admin/contexts/LocaleContext';
import {Box, Toolbar, Typography} from '@mui/material';
import {AvailableLocale} from '@brix/shared-frontend';

const PropertyDescriptionsTabs = ({onLocaleChange}: { onLocaleChange: (locale: string) => void }) => {

  const {locales} = useLocaleContext();
  const [selected, setSelected] = useState<string>();

  const handleLocaleSelected = (locale: string) => {
    setSelected(locale);
    onLocaleChange && onLocaleChange(locale);
  }

  useEffect(() => {
    setSelected(locales && locales[0]?.lcidCode);
  }, [locales]);

  return (
    <Toolbar disableGutters>
      <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
        {locales.map((locale: AvailableLocale) => (
          <Typography onClick={() => handleLocaleSelected(locale.lcidCode)}
                      sx={{
                        '&:hover': {opacity: 0.7},
                        transition: '0.2s ease-in-out',
                        mr: 5,
                        color: `${selected === locale.lcidCode ? '#cc4645' : ''}`,
                        cursor: 'pointer',
                        fontSize: '16px'
                      }}
                      key={locale.lcidCode}>
            {locale.lcidCode.toUpperCase() }
          </Typography>
        ))}
      </Box>
    </Toolbar>
  );
};

export default PropertyDescriptionsTabs;
