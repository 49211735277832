import {Box, Button, Card, CardContent, CircularProgress, Grid, MenuItem, TextField, Typography,} from '@mui/material';
import {useForm} from 'react-hook-form';
import { useParams } from 'react-router';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {api, loadCountries, loadStates} from '@admin/utils';
import {toast} from 'react-toastify';
import {
  errorHandler,
  getCountryId,
  getCountryIso3,
  setMultipleValues,
  useYupValidationResolver,
  IAddress
} from '@brix/shared-frontend';
import {useMutation, useQuery, useQueryClient} from 'react-query';

const validationSchema = Yup.object({
  street: Yup.string().required('This field is required'),
  street2: Yup.string(),
  city: Yup.string().required('This field is required'),
  zipCode: Yup.string().required('This field is required'),
  stateCode: Yup.string().required('This field is required'),
  countryId: Yup.string().required('This field is required'),
  longitude: Yup.string().required('This field is required'),
  latitude: Yup.string().required('This field is required'),
});

const updateAddress = async (propertyId: string, address: IAddress) => {
  const {data} = await api['properties'].patch(`/${propertyId}/address`, address);
  return data;
};

const getAddress = async (propertyId: string) => {
  const fileds = 'street,street2,city,zipCode,stateCode,countryId,longitude,latitude';
  const {data} = await api['properties'].get(`/${propertyId}/address?fields=${fileds}`);
  return data;
};

type TypeId = {
  id: string;
};

export const PropertyAddress = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const { id } = useParams<TypeId>();
  const queryClient = useQueryClient();
  const [countryCode, setCountryCode] = useState<string>('USA');

  const {register, handleSubmit, formState, setValue, watch} = useForm<IAddress>({
    resolver,
    mode: 'onChange',
  });
  const {errors} = formState;

  const {data: countries} = useQuery('countries', loadCountries);

  const {data: addressData, isLoading: isAddressLoading} = useQuery<IAddress, any>(
    ['address', id],
    () => getAddress(id),
    {
      onSuccess: (address: IAddress) => {
        setMultipleValues(setValue, address);
        setValue('countryId', getCountryIso3(address.countryId, countries));
        setCountryCode((address?.countryId?.length ?? 0) > 4 ? getCountryIso3(address?.countryId, countries) : address.countryId);
      },
      onError: (err: any) => {
        toast.error(errorHandler(err.data));
      },
    }
  );

  useEffect(() => {
    if (addressData) {
      setMultipleValues(setValue, addressData);
      setValue('countryId', getCountryIso3(addressData.countryId, countries));
      setCountryCode((addressData?.countryId?.length ?? 0) > 4 ? getCountryIso3(addressData?.countryId, countries) : addressData.countryId);
    }
  }, [addressData]);

  const {data: stateList} = useQuery(['states', {countryCode}], () => loadStates(countryCode));


  const onCountryChange = (val: string) => {
    setCountryCode(val);
    setValue('countryId', val);
  };

  const mutationUpdate = useMutation((data: IAddress) => updateAddress(id, data), {
    onSuccess: () => {
      toast.success('Address updated successfully');
      queryClient.resetQueries(['address', id]);
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const onSaveAddress = (address: IAddress) => {
    address.countryId = getCountryId(address.countryId, countries);
    mutationUpdate.mutate(address);
  };

  if (isAddressLoading) {
    return  (
    <Box justifyContent="center" display="flex">
    <CircularProgress/>
  </Box>
  )
  }

  else {
    return (
      <form onSubmit={handleSubmit(onSaveAddress)}>
        <Card>
          <CardContent>
                <Box>
                  <Typography variant="h6">Address</Typography>
                </Box>
                <Grid item>
                  <TextField
                    error={Boolean(errors?.street?.message)}
                    fullWidth
                    helperText={errors.street?.message}
                    defaultValue=" "
                    label="Address 1"
                    sx={{mt: 2}}
                    {...register('street')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    error={Boolean(errors.street2?.message)}
                    fullWidth
                    helperText={errors.street2?.message}
                    label="Address 2 (optional)"
                    sx={{mt: 2}}
                    {...register('street2')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Country"
                    select
                    id="country"
                    fullWidth
                    defaultValue={addressData ? (addressData?.countryId?.length ?? 0) > 4 ? getCountryIso3(addressData?.countryId, countries) : addressData.countryId : ''}
                    sx={{
                      mt: 2,
                    }}
                    error={Boolean(errors.countryId?.message)}
                    {...register('countryId')}
                    onChange={(e) => onCountryChange(e.target.value)}
                  >
                    {countries ? countries.map(({name, iso3}, idx) => (
                        <MenuItem key={idx} value={iso3}>
                          {name}
                        </MenuItem>
                      )) :
                      <MenuItem>Loading...</MenuItem>}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    label="State"
                    select
                    id="stateCode"
                    fullWidth
                    sx={{
                      mt: 2,
                    }}
                    error={Boolean(errors.stateCode?.message)}
                    {...register('stateCode')}
                    defaultValue={addressData?.stateCode}
                  >
                    {stateList ? stateList.map(({stateCode, name}, idx) => (
                        <MenuItem key={idx} value={stateCode}>
                          {name}
                        </MenuItem>
                      )) :
                      <MenuItem>Loading...</MenuItem>}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    error={Boolean(errors.city?.message)}
                    fullWidth
                    helperText={errors.city?.message}
                    label="City"
                    sx={{mt: 2}}
                    {...register('city')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    error={Boolean(errors.zipCode?.message)}
                    fullWidth
                    helperText={errors.zipCode?.message}
                    label="Zip code"
                    sx={{mt: 2}}
                    {...register('zipCode')}
                  />
                </Grid>
                <Grid item>
              <TextField
                error={Boolean(errors.longitude?.message)}
                helperText={errors.longitude?.message}
                fullWidth
                label="Longitude"
                sx={{
                  mt: 2,
                }}
                {...register('longitude')}
              />
            </Grid>
            <Grid item>
              <TextField
                error={Boolean(errors.latitude?.message)}
                helperText={errors.latitude?.message}
                fullWidth
                label="Latitude"
                sx={{
                  mt: 2,
                }}
                {...register('latitude')}
              />
            </Grid>
                <Grid container justifyContent="flex-end" spacing={3} pt={4}>
                  <Grid item>
                    <Button type="submit" disabled={formState.isSubmitting} variant="outlined">
                      Save Address
                    </Button>
                  </Grid>
                </Grid>
          </CardContent>
        </Card>
      </form>
    );
  }
};
