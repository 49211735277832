import React, { useEffect, useState } from 'react';
import { IPropertyAddress, IPropertySpecs, getStateName } from '@brix/shared-frontend';
import { loadStates } from '@admin/utils';
import { useQuery } from 'react-query';

const AddressMap: React.FC<{
    propertyData: IPropertySpecs;
    addressItem?: IPropertyAddress
}> = (propertyData) => {

    const [address, setAddress] = useState('')
    const [stateCode, setStateCode] = useState(propertyData.addressItem?.stateCode)

    const [countryCode, setCountryCode] = useState<string>('USA');
    const {data: stateList, refetch} = useQuery(['states', {countryCode}], () => loadStates(countryCode));

    useEffect(() => {
      if (propertyData.addressItem?.countryId) {
        setCountryCode(propertyData.addressItem?.countryId)
        setStateCode(getStateName(propertyData.addressItem?.stateCode, stateList))
      }
    }, [propertyData, stateList])
    
    
    useEffect(() => {

        if (propertyData.addressItem?.street2) {
            setAddress(`${propertyData.addressItem?.city}, ${stateCode} ${propertyData.addressItem?.zipCode}`)
        } else {
            setAddress(`${propertyData.addressItem?.city}, ${stateCode} ${propertyData.addressItem?.zipCode}`)
        }
    }, [propertyData, stateList, stateCode])

    if (!propertyData.addressItem) return null;

    return (
        <div>
            < iframe
                src={`//maps.google.com/maps?q=${address}&z=13&output=embed`}
                title="map"
                width="100%"
                height="450"
                style={{ border: 0 }}
                loading="lazy">
            </iframe>
        </div>
    );
}

export default AddressMap;
