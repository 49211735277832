import {Box, Button, Container, Grid, MenuItem, TextField, Typography} from '@mui/material';
import {Reports as ReportsIcon} from '../../icons/Reports';
import React, {useEffect} from 'react';
import {IPage} from '@brix/shared-frontend';

const DashboardView: React.FunctionComponent<IPage> = () => {
  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        backgroundColor: 'background.default',
        height: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{mb: 4}}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} sm={6} ><Typography variant='h4' color='primary' >Welcome</Typography></Grid>
            <Grid
              item
              xs={12} sm={6} 
              sx={{
                display: 'flex',
                alignItems: 'center',
                m: -1,
              }}
            >
              <Button startIcon={<ReportsIcon fontSize="small" />} sx={{m: 1}} variant="outlined">
                Reports
              </Button>
              <TextField defaultValue="week" label="Period" select size="small">
                <MenuItem value="week">Last week</MenuItem>
                <MenuItem value="month">Last month</MenuItem>
                <MenuItem value="year">Last year</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default DashboardView;
