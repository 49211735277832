import {useEffect, useState} from "react";
import {AvailableLocale} from '@brix/shared-frontend';
import {AxiosResponse} from 'axios';
import useApi from "./useApi";

const useLocales = () => {
  const {supportedLocales} = useApi();
  const [locales, setLocales] = useState(<Array<AvailableLocale>>[]);

  useEffect(() => {
    supportedLocales().get('/').then((response: AxiosResponse) => {
      setLocales(response?.data ?? []);
    });
  }, []);

  return {
    locales
  }
}

export default useLocales;
