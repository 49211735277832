import {IFinancial, setMultipleValues, TypeId, useYupValidationResolver, errorHandler} from '@brix/shared-frontend';
import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import * as Yup from 'yup';
import Divider from '@mui/material/Divider';
import NumericField from '@admin/views/dashboard/properties/[propertyId]/Financial/NumericField';
import DateField from '@admin/views/dashboard/properties/[propertyId]/Financial/DateField';
import {api} from '@admin/utils';
import {AxiosError, AxiosResponse} from 'axios';
import { toast } from 'react-toastify';

const numeric_label = 'This field has to be a number';

const greater_label = (field: string) => {
  return `${field} Amount must be greater than or equal to 0'`
}

const validationSchema = Yup.object({
  appreciationValue: Yup.number().typeError(numeric_label).min(0, greater_label('Appreciation Value')),
  maintenanceAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Maintenance')),
  taxesAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Tax')),
  insuranceAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Insurance')),
  reservesAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Reserve')),
  reservesPct: Yup.number().typeError(numeric_label).min(0, greater_label('Reserves PCT')),
  currentReserves: Yup.number().typeError(numeric_label).min(0, greater_label('Current Reserves')),
  reservesGoal: Yup.number().typeError(numeric_label).min(0, greater_label('Reserve Goal')),
  managementFeeAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Management Fee')),
  expensesAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Expenses')),
  rentalGrossAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Rental Gross')),
  rentalNetAmount: Yup.number().typeError(numeric_label),
  leaseStartAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Lease Start')),
  mortgageAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Mortgage')),
  mortgageBalanceAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Remaining Mortgage')),
  mortgageMonthlyAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Monthly Mortgage')),
  mortgageInterestAmount: Yup.number().typeError(numeric_label).min(0, greater_label('Mortgage Interest')),
});

export interface FinancialParams {
  financial?: IFinancial;
  setFinancial: Dispatch<SetStateAction<IFinancial | undefined>>;
}

export const Financial = ({financial, setFinancial}: FinancialParams) => {
  const {id} = useParams<TypeId>();
  const [loading, setLoading] = useState<boolean>(false);

  const {control, handleSubmit, formState: {errors, isSubmitting}, setValue} = useForm<IFinancial>({
    resolver: useYupValidationResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    financial && setMultipleValues(setValue, financial);
  }, [financial]);

  const handleSubmitFinancial = (financial: IFinancial) => {
    setLoading(true);
    api['/properties'].post(`/${id}/financial/${financial.id}/update`, financial).then((info: AxiosResponse) => {
      setMultipleValues(setValue, info.data);
      setLoading(false);
      setFinancial(info.data);
      toast.success('Financials updated successfully');
    }, (error: AxiosError) => {
      setLoading(false);
      console.log(error);
      toast.error(errorHandler(error));
    })
  }

  return (
    <Grid item xs={12} md={6}>
      <form onSubmit={handleSubmit(handleSubmitFinancial)} id="financial_form">
        <Grid container spacing={2} sx={{p: 1}}>
          {(loading || !financial) ?
            (
              <Box justifyContent="center" display="flex">
                <CircularProgress/>
              </Box>
            ) :
            (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Rental Info</Typography>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'rentalGrossAmount'} disabled={true}
                                error={errors?.rentalGrossAmount}
                                label="Rental Gross Amount"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'rentalNetAmount'} disabled={true}
                                error={errors?.rentalNetAmount}
                                label="Rental Net Amount"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'leaseDepositAmount'} disabled={true}
                                error={errors?.leaseDepositAmount}
                                label="Lease Deposit Amount"/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Appraisal Info</Typography>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'appreciationValue'}
                                error={errors?.appreciationValue}
                                label="Appreciation Value"/>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <DateField name="lastReappraisalDate" control={control}
                             label="Last Appraisal Date" maxDate={new Date()}/>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <DateField name="nextReappraisalDate" control={control}
                             label="Next Appraisal Date" minDate={new Date()}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Expenses</Typography>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'maintenanceAmount'}
                                error={errors?.maintenanceAmount}
                                label="HOA/Maintenance Amount"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'taxesAmount'}
                                error={errors?.taxesAmount}
                                label="Taxes Amount"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'insuranceAmount'}
                                error={errors?.insuranceAmount}
                                label="Insurance Amount"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'managementFeeAmount'} disabled={true}
                                error={errors?.managementFeeAmount}
                                label="Management Fee Amount"/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumericField control={control} prefix="$"
                                name={'expensesAmount'}
                                error={errors?.expensesAmount}
                                label="Other Expenses"/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Mortgage Info</Typography>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'mortgageAmount'}
                                error={errors?.mortgageAmount}
                                label="Mortgage Amount"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'mortgageBalanceAmount'}
                                error={errors?.mortgageBalanceAmount}
                                label="Remaining Mortgage Balance"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'mortgageMonthlyAmount'}
                                error={errors?.mortgageMonthlyAmount}
                                label="Monthly Mortgage Payment"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'mortgageInterestAmount'}
                                error={errors?.mortgageInterestAmount}
                                label="Monthly Mortgage Interest Payment"/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Reserve Info</Typography>
                  <Divider/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'reservesAmount'}
                                error={errors?.reservesAmount}
                                label="Beginning Reserve Balance"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'reservesGoal'}
                                error={errors?.reservesGoal}
                                label="Target Reserve Amount"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} suffix="%"
                                name={'reservesPct'}
                                error={errors?.reservesPct}
                                label="Reserve Payment Amount %"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NumericField control={control} prefix="$"
                                name={'currentReserves'} disabled={true}
                                error={errors?.currentReserves}
                                label="Current Reserve Balance"/>
                </Grid>
              </>
            )
          }
          <Grid container justifyContent="flex-end" spacing={3} pt={4}>
            <Grid item>
              <Button type="submit" disabled={isSubmitting} variant="outlined">
                Save Financials
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

