import { useState, useEffect } from 'react'
import { DropzoneArea } from 'react-mui-dropzone'
import { Box, Button } from '@mui/material';
import api from '@admin/utils/api';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import MediaCRUD from './MediaCRUD';
import { TypeId } from '@brix/shared-frontend';

const DragnDropMedia = () => {

    const [files, setFiles] = useState<any>([])
    const { id } = useParams<TypeId>();
    const [hasToRefetch, setHasToRefetch] = useState(false)
    const [mountComponent, setMountComponent] = useState(true)


    const handleChange = (files: any) => {
        setFiles(files)
    }

    useEffect(() => {
        if (!mountComponent) {
            setMountComponent(true)
        }
    }, [mountComponent])

    const uploadFiles = async (files: File[]) => {
        try {
            files.forEach(async (file: File) => {
                const formData = new FormData();
                const fileToUpload = file;
                formData.append('file', fileToUpload, fileToUpload.name);
                await api['files'].post(`/upload`, formData).then(async (res: any) => {
                    await api['media'].post(`/`, {
                        key: res.data.Location,
                        status: 0,
                        mediaType: 0,
                        propertyId: id,
                        metadata: {
                            mime: fileToUpload.type,
                            size: fileToUpload.size,
                        },
                    });
                });
                toast.success('Documents saved', {
                    position: 'top-left',
                    pauseOnHover: false,
                    closeOnClick: true,
                    autoClose: 3000,
                });
                setHasToRefetch(true)
                setMountComponent(false)
                setFiles([]);
            });
        } catch (e: any) {
            toast.error('Could not upload documents', {
                position: 'top-left',
                pauseOnHover: false,
                closeOnClick: true,
                autoClose: 3000,
            });
            return;
        }
    };

    const sendFiles = async () => {
        await uploadFiles(files)
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 2,
            gap: 4,
        }}>
                {
                    mountComponent ? 
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <DropzoneArea
                    onChange={handleChange}
                    filesLimit={10}
                    acceptedFiles={['image/*']}
                    dropzoneText={"Drop files to upload"}
                    maxFileSize={10000000}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={true}
                    previewText={""}
                    onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                />
                <Button sx={{
                    alignSelf: 'center',
                }} onClick={sendFiles}>
                    Submit
                </Button>
                </Box> : <></>
                }
            <MediaCRUD hasToRefetch={hasToRefetch} setHasToRefetch={setHasToRefetch} />
        </Box>
    )

}

export default DragnDropMedia;
