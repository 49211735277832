import { calculateReserveAmount, useYupValidationResolver, IPropertyCreate, firstLetterUppercase } from '@brix/shared-frontend';
import { Box, Button, Card, CardContent, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api, loadCountries, loadStates } from '@admin/utils';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DatePicker, {MobileDatePickerProps} from '@mui/lab/MobileDatePicker';

const validationSchema = Yup.object({
  name: Yup.string().max(25, 'Name must be smaller than 25 chars').required('This field is required'),
  descriptions: Yup.string().required('This field is required'),
  grossValue: Yup.number()
    .typeError('Gross value must be a valid number')
    .min(50000, 'Gross value must be more than 50K')
    .required('This field is required'),
  closingValue: Yup.number()
    .typeError('Closing value must be a valid number')
    .min(50000, 'Closing value must be more than 50K')
    .required('This field is required'),
  numberOfBathrooms: Yup.number()
    .typeError('Number of bathrooms must be a number')
    .min(1, 'Number of bathrooms must be greater than 0')
    .required('This field is required'),
  numberOfBedrooms: Yup.number()
    .typeError('Number of bedrooms must be a number')
    .min(1, 'Number of bedrooms must be greater than 0')
    .required('This field is required'),
  status: Yup.number().required('This field is required'),
  hoa: Yup.string().required('This field is required'),
  arv: Yup.number().typeError('Amount must be a number').required('This field is required'),
  size: Yup.number()
    .typeError('Size  must be a number')
    .min(1, 'Size must be greater than 0 ft')
    .required('This field is required'),
  folioID: Yup.string().required('This field is required'),
  type: Yup.number().typeError('Type must be a valid number').required('This field is required'),
  constructionYear: Yup.number()
    .typeError('Construction year must be a number')
    .test('len', 'Must be exactly 4 characters', (val) => val?.toString().length === 4)
    .max(new Date().getFullYear(), 'Year must be less than or equal to current year')
    .required('This field is required'),
  street: Yup.string().required('This field is required'),
  street2: Yup.string(),
  city: Yup.string().required('This field is required'),
  stateCode: Yup.string().min(0, 'Company must be greater than 3 charts').required('This field is required'),
  countryId: Yup.string().min(0, 'Company must be greater than 3 charts').required('This field is required'),
  zipCode: Yup.string().required('This field is required'),
  companyId: Yup.string().min(0, 'Company must be greater than 3 charts').required('This field is required'),
  longitude: Yup.string().required('This field is required'),
  latitude: Yup.string().required('This field is required'),
  saleType: Yup.number().required('This field is required'),
});

const createProperty = async (property: IPropertyCreate) => {
  property.name = firstLetterUppercase(property.name)
  const res = await api['/properties/create-property'].post('', property);
  return res.data;
};

const createFinancial = async (propertyId: string, reserveAmount: number) => {
  const res = await api['/properties'].post(`/${propertyId}/financial`, { reserveAmount });
  return res.data;
};

export const CreatePropertyForm = () => {
  const resolver = useYupValidationResolver(validationSchema);
  const [investors, setInvestors] = useState<any>([]);
  const [isValid, setIsValid] = useState(false)
  const [countryCode, setCountryCode] = useState<string>('USA');
  const queryClient = useQueryClient();
  const history = useHistory();
  const [date, setDate] = useState<Date | null>(null);

  const { isLoading, data: listInvestors } = useQuery(['companies'], () => api['/companies'].get(`/`));

  const { data: countries } = useQuery('countries', loadCountries, { keepPreviousData: true });

  useEffect(() => {
    if (listInvestors) {
      setInvestors(listInvestors?.data?.data);
    }
  }, [listInvestors]);

  const { register, handleSubmit, formState, setValue, reset, watch } = useForm<IPropertyCreate>({
    resolver,
    mode: 'onChange',
  });
  const { errors } = formState;

  const watchCountry = watch('countryId');

  useEffect(() => {
    setCountryCode(watchCountry)
  }, [watchCountry])

  useEffect(() => {
    setValue('countryId', countryCode);
  }, [])

  const { data: stateList } = useQuery(['states', { countryCode }], () => loadStates(countryCode), {
    keepPreviousData: true,
  });


  const mutation = useMutation((data: IPropertyCreate) => createProperty(data), {
    onSuccess: async (res: any) => {
      await createFinancial(res.id, calculateReserveAmount(Number(res.closingValue)));
      toast.success('Property has been created successfully');
      queryClient.resetQueries('properties');
      setIsValid(false);
      history.push(`/properties`);
    },
    onError: (err: any) => {
      toast.error(err.data.message);
      setIsValid(false);
    },
  });

  const onCreateProperty = (data: IPropertyCreate) => {
    setIsValid(true);
    const description = data.descriptions;
    data.descriptions = [
      {
        locale: 'en',
        value: description,
      },
    ];
    data.address = {
      street: data.street,
      street2: data.street2,
      city: data.city,
      stateCode: data.stateCode,
      countryId: data.countryId,
      zipCode: data.zipCode,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    mutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onCreateProperty)}>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="h6">Create Property</Typography>
          </Box>
          <Grid container spacing={2} sx={{ p: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                error={Boolean(errors.name?.message)}
                helperText={errors.name?.message}
                label="Name"
                sx={{
                  mt: 2,
                }}
                {...register('name')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.numberOfBathrooms?.message)}
                helperText={errors.numberOfBathrooms?.message}
                label="N° of Bathrooms"
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 0.5,
                  },
                }}
                sx={{
                  mt: 2,
                }}
                {...register('numberOfBathrooms')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.numberOfBedrooms?.message)}
                helperText={errors.numberOfBedrooms?.message}
                label="N° of Bedrooms"
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 0.5,
                  },
                }}
                sx={{
                  mt: 2,
                }}
                {...register('numberOfBedrooms')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.hoa?.message)}
                helperText={errors.hoa?.message}
                fullWidth
                label="HOA"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: 0.01,
                    max: 99999999,
                  },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                sx={{
                  mt: 2,
                }}
                {...register('hoa')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.closingValue?.message)}
                helperText={errors.closingValue?.message}
                fullWidth
                label="Closing Value"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 50000,
                    step: 0.01,
                    max: 99999999,
                  },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                sx={{
                  mt: 2,
                }}
                {...register('closingValue')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                select
                label="Type"
                id="type"
                fullWidth
                sx={{
                  mt: 2,
                }}
                error={Boolean(errors.type?.message)}
                {...register('type')}
                defaultValue="1"
              >
                <MenuItem value="1">
                  Apartment
                </MenuItem>
                <MenuItem value="2">
                  Condo
                </MenuItem>
                <MenuItem value="3">
                  Town House
                </MenuItem>
                <MenuItem value="4">
                  Single-family
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                select
                fullWidth
                sx={{
                  mt: 2,
                }}
                {...register('status')}
                id="status"
                error={Boolean(errors.status?.message)}
                label="Status"
                defaultValue="0"
              >
                <MenuItem value="0">
                  Pending
                </MenuItem>
                <MenuItem value="1">
                  For Sale
                </MenuItem>
                <MenuItem value="2">
                  Sold
                </MenuItem>
                <MenuItem value="3">
                  Occupied
                </MenuItem>
                <MenuItem value="4">
                  Unoccupied
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.grossValue?.message)}
                helperText={errors.grossValue?.message}
                fullWidth
                label="Gross Value"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 50000,
                    step: 0.01,
                    max: 99999999,
                  },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                sx={{
                  mt: 2,
                }}
                {...register('grossValue')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Size"
                error={Boolean(errors.size?.message)}
                helperText={errors.size?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" sx={{ mr: -1 }}>
                      ft
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 1,
                  },
                }}
                sx={{
                  mt: 2,
                }}
                {...register('size')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.constructionYear?.message)}
                helperText={errors.constructionYear?.message}
                label="C. Year"
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1800,
                  },
                }}
                sx={{
                  mt: 2,
                }}
                {...register('constructionYear')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.folioID?.message)}
                helperText={errors.folioID?.message}
                label="Folio ID"
                fullWidth
                sx={{ mt: 2 }}
                {...register('folioID')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Company"
                id="Company"
                select
                fullWidth
                sx={{
                  mt: 2,
                }}
                defaultValue=''
                error={Boolean(errors.companyId?.message)}
                {...register('companyId')}
              >
                {!isLoading ? (
                  investors && investors.length !== 0 ? investors.map((investor: any) => (
                    <MenuItem value={investor.id} key={investor.id}>
                      {investor.name}
                    </MenuItem>
                  )) :
                    <Typography>Company List is empty</Typography>
                ) : (
                  <MenuItem>Loading</MenuItem>
                )}
              </TextField>
              {Boolean(errors.companyId?.message) &&
                <p
                  className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained'
                  style={{
                    color: '#D14343',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    lineHeight: 1.66,
                    fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginBottom: 0,
                    marginLeft: '14px',
                    marginRight: '14px',
                  }}
                >
                  {errors.companyId?.message}
                </p>}
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                select
                label="Sale Type"
                id="saleType"
                fullWidth
                sx={{
                  mt: 2,
                }}
                error={Boolean(errors.saleType?.message)}
                {...register('saleType')}
                defaultValue="0"
              >
                <MenuItem value="0">
                  Pre-sale
                </MenuItem>
                <MenuItem value="1">
                  Offered
                </MenuItem>
                <MenuItem value="2">
                  Open-market
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sm={9}>
              <TextField
                multiline
                fullWidth
                maxRows={8}
                minRows={6}
                error={Boolean(errors.descriptions?.message)}
                helperText={errors.descriptions?.message}
                label="Description"
                sx={{ mt: 2 }}
                {...register('descriptions')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.arv?.message)}
                helperText={errors.arv?.message}
                fullWidth
                label="ARV"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    max: 99999999,
                  },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                sx={{
                  mt: 2,
                }}
                {...register('arv')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.street?.message)}
                helperText={errors.street?.message}
                label="Address 1"
                fullWidth
                sx={{ mt: 2 }}
                {...register('street')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Country"
                select
                id="country"
                fullWidth
                sx={{
                  mt: 2,
                }}
                error={Boolean(errors.countryId?.message)}
                {...register('countryId')}
                defaultValue=''
              >
                {countries ? countries.map(({ name, iso3 }, idx) => (
                  <MenuItem key={idx} value={iso3}>
                    {name}
                  </MenuItem>
                ))
                  :
                  <MenuItem>Loading</MenuItem>}
              </TextField>
              {Boolean(errors.countryId?.message) &&
                <p
                  className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained'
                  style={{
                    color: '#D14343',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    lineHeight: 1.66,
                    fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginBottom: 0,
                    marginLeft: '14px',
                    marginRight: '14px',
                  }}
                >
                  {errors.countryId?.message}
                </p>}
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="State"
                select
                id="stateCode"
                fullWidth
                sx={{
                  mt: 2,
                }}
                {...register('stateCode')}
                error={Boolean(errors.stateCode?.message)}
                defaultValue=''
              >
                {stateList ? stateList.map(({ stateCode, name }, idx) => (
                  <MenuItem key={idx} value={stateCode}>
                    {name}
                  </MenuItem>
                )) :
                  <MenuItem>Loading</MenuItem>}
              </TextField>
              {Boolean(errors.stateCode?.message) &&
                <p
                  className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained'
                  style={{
                    color: '#D14343',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    lineHeight: 1.66,
                    fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginBottom: 0,
                    marginLeft: '14px',
                    marginRight: '14px',
                  }}
                >
                  {errors.stateCode?.message}
                </p>}
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.city?.message)}
                helperText={errors.city?.message}
                label="City"
                fullWidth
                sx={{ mt: 2 }}
                {...register('city')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.street2?.message)}
                helperText={errors.street2?.message}
                label="Address 2"
                fullWidth
                sx={{ mt: 2 }}
                {...register('street2')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.zipCode?.message)}
                helperText={errors.zipCode?.message}
                label="Zip"
                fullWidth
                sx={{ mt: 2 }}
                {...register('zipCode')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.longitude?.message)}
                helperText={errors.longitude?.message}
                fullWidth
                label="Longitude"
                sx={{
                  mt: 2,
                }}
                InputProps={{
                  inputProps: {
                    min: -99999999,
                    step: 0.01,
                    max: 99999999,
                  },
                }}
                {...register('longitude')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                error={Boolean(errors.latitude?.message)}
                helperText={errors.latitude?.message}
                fullWidth
                label="Latitude"
                sx={{
                  mt: 2,
                }}
                InputProps={{
                  inputProps: {
                    min: -99999999,
                    step: 0.01,
                    max: 99999999,
                  },
                }}
                {...register('latitude')}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={3} pt={4}>
            <Grid item>
              <Button variant="outlined" onClick={() => reset()}>
                Discard
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" disabled={formState.isSubmitting || isValid}>
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};
