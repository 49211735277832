import { Grid, Button, TextField, MenuItem, Typography, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '@admin/utils/api';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
    errorHandler,
    TypeId,
    Infographic
} from '@brix/shared-frontend';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { NumericFormat } from 'react-number-format';
import Switch from '@mui/material/Switch';

const brixAvailability = async (propertyId: string, brixAvailables: number, typeOfBrixAvailable: number, totalBRiXies: number) => {

    if (typeOfBrixAvailable === 0) {
        const res = await api['/properties'].post(`/availabilities`, {
            type: typeOfBrixAvailable,
            amount: (totalBRiXies - brixAvailables),
            propertyId: propertyId,
        });
        return res.data;
    } else {
        const res = await api['/properties'].post(`/availabilities`, {
            type: typeOfBrixAvailable,
            amount: (1 - (brixAvailables * 0.01)),
            propertyId: propertyId,
        });
        return res.data;
    }
};

const getInfographics = async (propertyId: string) => {
    const { data } = await api['properties'].get(`/${propertyId}/infographics`);
    return data;
};

const BrixAvailableModule = (props: any) => {
    const { id } = useParams<TypeId>();
    const queryClient = useQueryClient();
    const { closingValue, refetchData } = props
    const [brixAvailables, setBrixAvailables] = useState(0)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [infographics, setInfographics] = useState<Infographic>({});
    const [typeOfBrixAvailable, setTypeOfBrixAvailable] = useState(0)
    const [porcentualAvailableBrix, setPorcentualAvailableBrix] = useState(0)
    const [totalBRiXies, setTotalBRiXies] = useState(0)
    const [isBRiXiesCustom, setIsBRiXiesCustom] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const { data: infographicsData, isLoading: isInfographicsLoading, refetch: refetchInfographics } = useQuery<any, any>(
        ['properties', 'infographics', id],
        () => getInfographics(id),
        {
            onSuccess: (data: any) => {
                setInfographics(data)
            },
            onError: (err: any) => {
                toast.error(errorHandler(err.data));
            },
        }
    );

    useEffect(() => {

        if (infographicsData) {
            setInfographics(infographicsData)
            setPorcentualAvailableBrix(
                (infographicsData.available * 100) / (infographicsData.available + infographicsData.sold + infographicsData.unavailable)
            )
            setTotalBRiXies(infographicsData.available + infographicsData.sold + infographicsData.unavailable)

            if ((infographicsData.available * 100) / (infographicsData.available + infographicsData.sold + infographicsData.unavailable) === 90) {
                setIsBRiXiesCustom(false)
            } else {
                setIsBRiXiesCustom(true)
            }
        }
    }, [infographicsData])


    const mutationBrixAvailability = useMutation(() => brixAvailability(id, brixAvailables, typeOfBrixAvailable, totalBRiXies), {
        onSuccess: (data: any) => {
            toast.success(`Property set ${(data.available - data.updated).toFixed(0)} BRiXies available successfully`)
            refetchInfographics();
            queryClient.resetQueries('properties');
            refetchData();
        },
        onError: (err: any) => {
            toast.error(errorHandler(err.data));
            refetchInfographics();
            refetchData();
        },
    });

    const mutationBrixAvailabilityStandard = useMutation(() => brixAvailability(id, Number(90), Number(1), totalBRiXies), {
        onSuccess: (data: any) => {
            toast.success(`Property set standard BRiXies calculation successfully`)
            refetchInfographics();
            setIsBRiXiesCustom(false)
            setIsOpenModal(false)
            queryClient.resetQueries('properties');
            refetchData();
        },
        onError: (err: any) => {
            toast.error(errorHandler(err.data));
            refetchInfographics();
            setIsOpenModal(false)
            refetchData();
        },
    });

    const setBrixAvailability = () => {
        setIsButtonDisabled(true)
        mutationBrixAvailability.mutate();
    }

    const setBrixAvailabilityStandard = () => {
        mutationBrixAvailabilityStandard.mutate();
    }

    const onAvailableChange = (val: string) => {
        setTypeOfBrixAvailable(Number(val));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsBRiXiesCustom(event.target.checked)
        if (!event.target.checked) {
            setIsOpenModal(true)
        }
    };

    const onBrixAvailablesChange = (val: number) => {
        setIsButtonDisabled(false)
        setBrixAvailables(val);
    };

    const onCancelStandardBRIxies = () => {
        setIsBRiXiesCustom(true)
        setIsOpenModal(false)
    }

    return (
        <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Limit the available BRiXies for a property </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexDirection: 'row',

                }}>
                    <Typography variant="h6">Standard</Typography>
                    <Switch
                        checked={isBRiXiesCustom}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='medium'
                    />
                    <Typography variant="h6">Custom</Typography>
                </Box>
            </Box>
            {
                isBRiXiesCustom ? (
                    <Grid container
                        spacing={4}
                        justifyContent="space-around"
                        alignItems="center"
                        sx={{
                            mt: 2,
                        }} >
                        <Grid item xs={9} sm={3}>
                            <Typography variant="h6">
                                Total BRiXies: {totalBRiXies}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={3}>
                            <TextField
                                label="Type of amount of BRiXies to sell"
                                select
                                sx={{
                                    mt: 2,
                                }}
                                onChange={(e) => onAvailableChange(e.target.value)}
                                defaultValue={0}
                                fullWidth
                            >
                                <MenuItem value={'0'}>
                                    Fixed Amount
                                </MenuItem>
                                <MenuItem value={'1'}>
                                    Percentage Amount
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={9} sm={3}>
                            <NumericFormat
                                customInput={TextField}
                                thousandSeparator=","
                                decimalSeparator="."
                                allowNegative={false}
                                fullWidth
                                onValueChange={(v) => onBrixAvailablesChange(Number(v.floatValue))}
                                decimalScale={0}
                                label={typeOfBrixAvailable === 0 ? "BRiXies Availables" : "BRiXies Percentage Available"}
                                sx={{ mt: 2 }}
                                suffix={typeOfBrixAvailable === 0 ? '' : '%'}
                                defaultValue={typeOfBrixAvailable === 0 ? brixAvailables : porcentualAvailableBrix}
                                value={typeOfBrixAvailable === 0 ? infographics?.available : porcentualAvailableBrix}
                                isAllowed={(values) => {
                                    const { value } = values;
                                    if (typeOfBrixAvailable === 0) {
                                        return Number(value) < totalBRiXies + 0.01;
                                    } else {
                                        return Number(value) < 100.01;
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={3}
                            sx={{
                                mt: 1.5,
                            }}>
                            <Button onClick={setBrixAvailability} autoFocus color="primary" fullWidth variant="contained" disabled={isButtonDisabled}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    null
                )
            }
            <Dialog
                open={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to set the property's BRiXies availabilities as standard calculation?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => onCancelStandardBRIxies()}>Cancel</Button>
                    <Button onClick={setBrixAvailabilityStandard} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default BrixAvailableModule;
