import {IPropertySpecs} from '@brix/shared-frontend';
import {Box, Typography} from '@mui/material';

const About: React.FC<{propertyData: IPropertySpecs}> = (propertyData) => {
  return (
    <Box>
      <Typography sx={{color: 'black', mt: 4, mb: 2}} variant="subtitle2">
        About Property
      </Typography>
      <Typography sx={{color: 'gray'}} variant="body1">
        {propertyData.propertyData.description}
      </Typography>
    </Box>
  );
};

export default About;
