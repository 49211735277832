import {Button, Grid, SvgIcon, Typography, Box} from '@mui/material';
import {useState, useEffect} from 'react';
import {IPropertyAddress, firstLetterUppercase} from '@brix/shared-frontend';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TourOutlinedIcon from '@mui/icons-material/TourOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import {PropertyTypes, PropertyStatusTypes, PropertyRentalTypes} from '@brix/shared-frontend';

interface ISubHeader {
  addressItem?: IPropertyAddress;
  lotSize?: number;
  size?: number;
  type?: string;
  rentalStatus?: string;
  status?: number;
  openBrixModal?: () => void;
}

const SubHeader: React.FunctionComponent<ISubHeader> = ({
  addressItem,
  type,
  status,
  openBrixModal,
}) => {
  const [onWishlist, setOnWishlist] = useState(false);

  const handleAddToWishlistButtonClick = () => {
    setOnWishlist(!onWishlist);
  };

  const propertyTypeLabel = PropertyTypes[Number(type)];
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Box>
          {addressItem !== undefined && (
            <Button disabled startIcon={<LocationOnOutlinedIcon fontSize="small" />}>
              <Typography color="gray" sx={{textTransform: 'none', fontSize: '12px', fontWeight: 600}} variant="body2">
                {addressItem !== null
                  ? addressItem.city !== null
                    ? addressItem.city + ', ' + addressItem.stateCode + ', ' + addressItem.zipCode
                    : ' ' + addressItem.stateCode + ', ' + addressItem.zipCode
                  : 'Unspecified'}
              </Typography>
            </Button>
          )}
          {type !== undefined && (
            <Button disabled startIcon={<HomeIcon fontSize="small" />}>
              <Typography color="gray" sx={{textTransform: 'none', fontSize: '12px', fontWeight: 600}} variant="body2">
                {type !== null && propertyTypeLabel ? firstLetterUppercase(propertyTypeLabel) : ' '}
              </Typography>
            </Button>
          )}
          {status !== undefined && (
            <Button disabled startIcon={<TourOutlinedIcon fontSize="small" />}>
              <Typography color="gray" sx={{textTransform: 'none', fontSize: '12px', fontWeight: 600}} variant="body2">
                {PropertyRentalTypes[Number(status)]}
              </Typography>
            </Button>
          )}
        </Box>
      </Grid>
      <Grid item sx={{mt: {xs: 1, md: 0}}}>
        <Box sx={{
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          gap: {xs: '10px', sm: '0px'},
        }}>
          <Button onClick={openBrixModal} variant="contained" color="primary" sx={{mr: 1}}>
            {'Buy'}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            sx={{mr: 1}}
            startIcon={
              <SvgIcon fontSize="small">
                <ShareIcon />
              </SvgIcon>
            }
          >
            {'Share'}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleAddToWishlistButtonClick}
            startIcon={<SvgIcon fontSize="small">{onWishlist ? <FavoriteIcon /> : <FavoriteBorderIcon />}</SvgIcon>}
          >
            {'Wishlist'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SubHeader;
