import {useState} from 'react';
import Head from 'next/head';
import {IPage} from '@brix/shared-frontend';
import {Box, Container, Divider, Tab, Tabs} from '@mui/material';
import {GeneralSettings} from '@admin/components/dashboard/settings/GeneralSettings';

const tabs = [{label: 'General Settings', value: 'general'}];

const SettingsView: React.FunctionComponent<IPage> = () => {
  const [currentTab, setCurrentTab] = useState('general');

  const handleTabsChange = (event: any, value: string) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Head>
        <title>Dashboard | Settings</title>
      </Head>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="md">
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
            sx={{mt: 3}}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider sx={{mb: 3}} />
          {currentTab === 'general' && <GeneralSettings />}
        </Container>
      </Box>
    </>
  );
};

export default SettingsView;
