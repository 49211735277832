import { PropertyListItem } from '@admin/components/common';
import { errorHandler } from '@brix/shared-frontend';
import { Button, Card, CardHeader, Divider, List, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { api } from '@admin/utils';

export const UserInfo = (props: any) => {
  const { email, firstName, lastName, roles, status } = props;
  const [rol, setRol] = useState<string>('');

  const sendRecoverPassword = async (email: string) => {
    try {
      await api['/auth'].post('/recover-password', { email });
      toast.success(`Reset link for ${firstName} ${lastName} sent successfully`);
    } catch (e: any) {
      toast.error(errorHandler(e.data));
    }
  };

  useEffect(() => {

    const rol: string[] = []

    roles.map((role: string) => {

      switch (role) {
        case 'admin':
          rol.push('Admin')
          break;
        case 'user':
          rol.push('User')
          break;
        case 'superAdmin':
          rol.push('Super Admin')
          break;
        case 'investor':
          rol.push('Investor')
          break;
      }

    })
    setRol(rol.join(', '))
  }, [props]);

  return (
    <Card>
      <CardHeader title="User Info" />
      <Divider />
      <List disablePadding>
        <PropertyListItem align={'left'} divider label="First Name" value={firstName} />
        <PropertyListItem align={'left'} divider label="Last Name" value={lastName} />
        <PropertyListItem align={'left'} divider label="Email" value={email} />
        <PropertyListItem align={'left'} divider label="Role" value={rol} />
        <PropertyListItem align={'left'} divider label="Status" value={status} />
        <Box sx={{ p: 2 }}>
          <Button variant="outlined" onClick={() => sendRecoverPassword(email)}>
            Send Reset Password Link
          </Button>
        </Box>
      </List>
    </Card>
  );
};
