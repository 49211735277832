import {formatPrice, IFinancial} from '@brix/shared-frontend';
import {Box, Button, Card, CardContent, Grid, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const getFinancialData = (financial?: IFinancial) => {
  const reservePayment = financial?.reservesPct && financial?.rentalGrossAmount ? Number((financial.rentalGrossAmount * financial.reservesPct) / 100) : 0;
  return [
    {title: 'HOA/Maintenance Amount', value: Number(financial?.maintenanceAmount ?? 0), color: '#CC4645'},
    {title: 'Taxes Amount', value: Number(financial?.taxesAmount ?? 0), color: 'blue'},
    {title: 'Insurance Amount', value: Number(financial?.insuranceAmount ?? 0), color: 'yellow'},
    {title: 'Management Fee Amount', value: Number(financial?.managementFeeAmount ?? 0), color: 'green'},
    {title: 'Other Expenses', value: Number(financial?.expensesAmount ?? 0), color: '#491e75'},
    {title: 'Monthly Mortgage Payment', value: Number(financial?.mortgageMonthlyAmount ?? 0), color: 'black'},
    {title: 'Monthly Mortgage Interest Payment', value: Number(financial?.mortgageInterestAmount ?? 0), color: 'gray'},
    {title: 'Reserve Payment', value: reservePayment, color: 'cyan'},
    {title: 'Rental Net Amount', value: Number(financial?.rentalNetAmount ?? 0), color: '#009A79'},
  ];
}

export interface FinancialParams {
  financial?: IFinancial;
  setIndex?: (index: number) => void
}

export interface ChartParams {
  title: string,
  value: number,
  color: string
}

const FinancialChart = ({financial, setIndex}: FinancialParams) => {
  const [dataPieChart, setDataPieChart] = useState<Array<ChartParams>>(getFinancialData());
  const [chart, setChart] = useState(dataPieChart);
  const [totalExpenses, setTotalExpenses] = useState<number>(0)

  useEffect(() => {
    financial && updateFinancialChart()
  }, [financial]);

  const updateFinancialChart = () => {
    const data = getFinancialData(financial);
    setDataPieChart(data);
    setChart(data.slice(0, -1));
    const totalExpensesData = data.slice(0, -1).reduce((acc, item) => {
      return acc + item.value;
    }, 0);

    setTotalExpenses(totalExpensesData);
  }

  const total = () => {
    let total = 0;
    for (let i = 0; i < dataPieChart.length; i++) {
      total += dataPieChart[i].value;
    }
    return Number(total);
  };

  const totalPorcentual = () => {
    let porcentual = 0;
    for (let i = 0; i < dataPieChart.length - 1; i++) {
      porcentual += dataPieChart[i].value;
    }
    return Number(porcentual);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} direction="column" alignItems="center" sx={{mb: 2}}>
          <Grid item>
            <PieChart
              data={dataPieChart}
              animate
              label={() => formatPrice(financial?.rentalGrossAmount)}
              labelStyle={{
                fontSize: '18px',
                fontWeight: 600,
                fontFamily: 'sans-serif',
              }}
              totalValue={total()}
              startAngle={270}
              labelPosition={0}
              lineWidth={15}
            />
            <Typography variant="subtitle1" sx={{mt: '-38%', pl: 8, opacity: 0.7, mb: '38%', ml: '10px'}}>
              Rental Gross Amount
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          gap: 1,
        }}>
          <Box>
            <Typography variant="h5" sx={{mb: 3, mt: -3, fontWeight: 600, color: '#009A79'}}>
              {formatPrice(financial?.rentalNetAmount)}
            </Typography>
            <Typography variant="subtitle1" sx={{mb: 3, mt: -3}}>
              Rental Net Amount
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" sx={{mb: 3, mt: -3, fontWeight: 600, color: '#009A79'}}>
              {formatPrice(totalExpenses)}
            </Typography>
            <Typography variant="subtitle1" sx={{mb: 3, mt: -3}}>
              Total Expenses
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={0} alignItems="center" style={{height: '100%', display: 'flex'}}>
          {chart.map((item: ChartParams, index: number) => (
            <Grid item xs={6} sm={6} key={index}>
              <Box sx={{display: 'flex', mb: 2}}>
                <FiberManualRecordIcon fontSize="small" sx={{color: `${item.color}`, mt: 2, mr: 1}}/>
                <Box>
                  <Typography variant="body1" sx={{fontWeight: 600}}>
                    {formatPrice(item.value)}{' '}
                    <span style={{color: '#87CEFA', fontSize: '13px'}}>
                      {totalPorcentual() > 0 ? `${(((item.value * 100) / totalPorcentual()).toFixed(2))}%` : '0%'}
                    </span>
                  </Typography>
                  <Typography variant="body2" sx={{color: 'gray', fontWeight: 500, opacity: 0.8}}>
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
          {setIndex && (
            <Button variant="contained" onClick={() => setIndex(2)}>
              View
            </Button>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FinancialChart;
