import {Box, Button, Card, CardContent, Grid, TextField, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import PropertyDescriptionsTabs from '@admin/components/property/PropertyDescriptionsTabs';
import {useLocaleContext} from '@admin/contexts/LocaleContext';
import {IPropertyDescription, setMultipleValues, useYupValidationResolver, TypeId} from '@brix/shared-frontend';
import {useParams} from 'react-router';
import {AxiosResponse} from 'axios';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import useApi from '@admin/hooks/useApi';
import {toast} from 'react-toastify';

type PropertyDescription = Array<IPropertyDescription>;

const schema = Yup.object({
  value: Yup.string().required('This field is required'),
  locale: Yup.string().required('This field is required'),
  propertyId: Yup.string().required('This field is required'),
});

export const PropertyDescriptions = () => {
  const {properties} = useApi();
  const {locales} = useLocaleContext();
  const [descriptions, setDescriptions] = useState<PropertyDescription>([]);
  const { id } = useParams<TypeId>();

  const {register, handleSubmit, formState, setValue} = useForm<IPropertyDescription>({
    resolver: useYupValidationResolver(schema),
    mode: 'onChange',
  });

  const loadDescriptions = () => {
    properties().get(`/${id}/descriptions`).then((response: AxiosResponse<PropertyDescription>) => {
      const descriptions = response.data;
      const propertyDescriptions = locales.map(locale => {
        return {
          value: descriptions.find(d => d.locale === locale.lcidCode)?.value ?? '',
          locale: locale.lcidCode,
          propertyId: id
        } as IPropertyDescription;
      });
      setDescriptions(propertyDescriptions);
      setSelected(propertyDescriptions[0]);
    })
  }

  useEffect(loadDescriptions, [locales]);

  const handleLocaleChanged = (locale: string) => {
    setSelected(descriptions.find(d => d.locale === locale));
  }

  const setSelected = (selected?: IPropertyDescription) => {
    setMultipleValues(setValue, selected ?? {});
  }

  const onSaveDescription = (description: IPropertyDescription) => {
    properties().post(`/${id}/descriptions`, description).then(response => {
      if ([200, 201].includes(response.status)) {
        const existing = descriptions.find(d => d.locale === description.locale);
        if (existing) {
          existing.value = description.value;
        }
        toast.success('Description saved successfully');
      }else{
        toast.error('Description could not be saved');
      }
    })
  };

  return (
    <form onSubmit={handleSubmit(onSaveDescription)}>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="h6">Property Description</Typography>
          </Box>
          <PropertyDescriptionsTabs onLocaleChange={handleLocaleChanged}/>
          <Grid item xs={12} md={12}>
            <TextField multiline fullWidth maxRows={8}
                       sx={{mt: 2}} minRows={6}
                       error={Boolean(formState.errors?.value?.message)}
                       helperText={formState.errors?.value?.message}
                       {...register('value')}/>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={3} pt={4}>
            <Grid item>
              <Button type="submit"
                      disabled={formState.isSubmitting}
                      variant="outlined">
                Save Description
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};
