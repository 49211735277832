export const sum = (...numbers: (number | undefined)[]) => {
  return numbers.reduce((a?: number, b?: number) => orCero(a) + orCero(b), 0)
}

export const orCero = (num?: number): number => {
  if (isNaN(num ?? NaN)) {
    return 0;
  }
  return Number(num);
}
