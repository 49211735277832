import {Box, Container, Link} from '@mui/material';
import React, {useEffect} from 'react';
import {Column} from '@material-table/core';
import moment from 'moment';
import {IPage, AddingStyleHeader, formatWithDigits} from '@brix/shared-frontend';
import {BrixTable} from '@admin/components/common';
import {booleanToString} from '@admin/utils';
import {useHistory} from 'react-router';

const columns: Column<any>[] = [
  {
    title: 'Property Address',
    field: 'addressStreet',
    customSort: (a, b) => a.addressStreet.toLowerCase() - b.addressStreet.toLowerCase(),
    type: 'string',
    editable: 'never',
  },
  {
    title: 'Year built',
    field: 'yearBuilt',
    type: 'string',
    editable: 'never',
  },

  {
    title: 'Is Property Rented',
    field: 'isPropertyRented',
    type: 'boolean',
    editable: 'never',
    render: (rowData) => booleanToString(rowData.isPropertyRented),
  },
  {
    title: 'Monthly Payment',
    field: 'monthlyPayment',
    customSort: (a, b) => a.monthlyPayment - b.monthlyPayment,
    type: 'string',
    editable: 'never',
    render: (rowData) => <span>{formatWithDigits(rowData.monthlyPayment)}</span>,
  },

  {
    title: 'Has Mortgage',
    field: 'hasMortgage',
    type: 'string',
    editable: 'never',
    render: (rowData) => booleanToString(rowData.hasMortgage),
  },
  {
    title: 'Submission Date',
    field: 'createdAt',
    type: 'string',
    editable: 'never',
    render: (data) => <span>{moment(data.createdAt).format('MM-DD-YYYY')}</span>,
  },
];

const ContributingPropertiesListView: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = 'Fractionalize Properties List';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{mb: 4}}>
          <BrixTable
            title="Fractionalize Properties List"
            endpoint="/contributing-properties"
            isRowDelete={true}
            columns={AddingStyleHeader(columns)}
            onRowClick={(_, rowData) => history.push(`/fractionalize-properties/${rowData?.id}`, rowData)}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default ContributingPropertiesListView;
