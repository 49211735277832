export enum PropertyStatusTypes {
  pending = 'Pending',
  forSale = 'For Sale',
  sold = 'Sold',
  occupied = 'Occupied',
  unoccupied = 'Unoccupied',
}

export enum PropertyRentalTypes {
  Pending,
  'For sale',
  Sold,
  Occupied,
  Unoccupied,
}

export enum PropertyStatusPublishedTypes {
  published = 'Published',
  unpublished = 'Unpublished',
}

export enum ConstructionTypes {
  brick = 'brick',
  concrete = 'concrete',
  wood = 'wood',
  steel = 'steel',
  prefab = 'prefab',
  adobe = 'adobe',
  other = 'other',
}

export enum PropertyTypes {
  house = 0,
  apartment = 1,
  condo = 2,
  townhouse = 3,
  'single-family' = 4,
}

export enum PropertySaleTypes {
  'Pre-sale' = 0,
  Offered = 1,
  'Open-market' = 2,
}

export enum BrixTypes {
  Brixie,
  HalfBrix,
  Brix,
  Stax,
}
