import {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {IPage} from '@brix/shared-frontend';
import {Box, Card, Container, Divider, Typography} from '@mui/material';
import ResetPasswordForm from './ResetPasswordForm';
import Logo from '@admin/components/Logo';

const PasswordResetView: React.FC<IPage> = () => {
  useEffect(() => {
    document.title = 'Recover password';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          py: {
            xs: '60px',
            md: '120px',
            lg: '60px',
          },
        }}
      >
        <Card elevation={16} sx={{p: 4}}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <RouterLink to="/">
              <Logo height={80} width={80} />
            </RouterLink>
            <Typography variant="h4">Password Reset</Typography>
            <Typography color="textSecondary" sx={{mt: 2}} variant="body2">
              Reset your account password using your code
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <ResetPasswordForm />
            </Box>
          </Box>
          <Divider sx={{my: 3}} />
        </Card>
      </Container>
    </Box>
  );
};

export default PasswordResetView;
