import {Select, MenuItem} from '@mui/material';
import React from 'react';
import {IFormSelect} from './interfaces';

export const FormSelect = ({name, options, value, onChange}: IFormSelect) => {
  return (
    <Select defaultValue={value} name={name} onChange={onChange} displayEmpty>
      {options?.map(({value, label}, idx) => (
        <MenuItem key={idx} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
