import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tabs from './components/Tabs';
import { useLocation } from 'react-router';
import { BrixTable } from '@admin/components/common';
import { CompanyAddress } from './components/CompanyAddress';
import { useParams, useHistory } from 'react-router';
import { getPropertyStatus, PropertyStatusTypes, IPage, TypeId } from '@brix/shared-frontend';
import { Column } from '@material-table/core';

const sections = [
  {
    title: 'Address',
    value: 1,
  },
  {
    title: 'Properties',
    value: 2,
  }
];

const columns: Column<any>[] = [
  {
    title: 'Name',
    field: 'name',
    type: 'string',
  },
  {
    title: 'Closing Value',
    field: 'closingValue',
    type: 'string',
  },
  {
    title: 'Gross Value',
    field: 'grossValue',
    type: 'string',
  },
  {
    title: 'Status',
    field: 'status',
    type: 'string',
    render: (rowData) => getPropertyStatus(rowData.status),
    initialEditValue: PropertyStatusTypes.pending,
  },
];

const CompanyDetailsView: React.FunctionComponent<IPage> = () => {
  useEffect(() => {
    document.title = 'Companies';
  }, []);

  const [tabSelected, setTabSelected] = useState(1);
  const [companyName, setCompanyName] = useState('')
  const [refetch, setRefetch] = useState(false);
  const { state } = useLocation<any>();

  const emitChange = (value: any) => {
    setTabSelected(value);
  };
  const history = useHistory();

  const { id } = useParams<{id: string}>();


  useEffect(() => {
    setCompanyName(state.name);
  }, [state])

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Tabs sections={sections} emitChange={emitChange} propertyName={companyName} />
        <Grid container spacing={3} sx={{ p: 1 }}>
          {tabSelected === 1 ? (
            <Grid container spacing={3} sx={{ p: 1 }}>
              <Grid item xs={6}>
                <CompanyAddress />
              </Grid>
            </Grid>
          ) : null}
          {tabSelected === 2 ? (
            <Grid item xs={12}>
              <BrixTable
                endpoint="properties"
                title="Properties"
                columns={columns}
                onRowClick={(_, row) => history.push(`/properties/${row.id}`, row)}
                defaultFilter="companyId"
                searchFilter={id}
                hasToRefetch={refetch}
                setRefetch={setRefetch}
              />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyDetailsView;
