import React, { useState, useEffect } from 'react';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import TablePagination from '@mui/material/TablePagination';
import { searchParmaBuilder, errorHandler, IResponseAPI, ITableState } from '@brix/shared-frontend';
import { ITable } from './interfaces';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { api } from '@admin/utils';
import { AxiosError } from 'axios';

const fetchTableData = async (
  endpoint: string,
  includes: string,
  limit: number,
  offset: number,
  sort: string,
  search: string,
  filter?: string,
) => {
  const res = await api[`${endpoint}`].get('/', {
    limit: limit,
    sort: sort,
    offset: offset,
    search: search,
    filter: filter ? [`companyId||$eq||${filter}`] : [],
    include: includes ? [includes] : '',
  }
  );
  return res.data;
};


export const BrixTable = ({
  title,
  endpoint,
  isRowDelete,
  includes = '',
  onDeleteComplete,
  onRowAdd,
  onRowUpdate,
  columns,
  searchFilter,
  defaultFilter,
  hasToRefetch,
  setRefetch,
  ...props
}: ITable): JSX.Element => {
  const [tableState, setTableState] = useState<ITableState>({
    limit: 10,
    page: 0,
    sort: 'createdAt,DESC',
    search: '{}',
    filter: searchFilter
  });


  const getFetchParams = () => {
      return {
        limit: tableState.limit,
        offset: tableState.page * tableState.limit,
        sort: tableState.sort,
        search: tableState.search,
        includes,
      }
  };

  const {
    data: dataFetch,
    isLoading: isDataFetchLoading,
    error: isDataFetchError,
    refetch: refetchData,
  } = useQuery<IResponseAPI<any>, AxiosError>(
    [endpoint, searchFilter, getFetchParams()],
    () =>
      fetchTableData(
        endpoint,
        includes,
        tableState.limit,
        tableState.page * tableState.limit,
        tableState.sort,
        tableState.search,
        tableState.filter,
      ),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
      setTableState({...tableState, filter: searchFilter})
      refetchData();
  }, [searchFilter])


  // solved bug about refetchData when added new row or updated row
  useEffect(() => {
    if (hasToRefetch) {
      refetchData();
      setRefetch && setRefetch(false);
    }
  }, [hasToRefetch])

  useEffect(() => {
    if (isDataFetchError) {
      showErrorToast(isDataFetchError);
    }
  }, [dataFetch, isDataFetchLoading, isDataFetchError]);

  const showErrorToast = (e: any) => {
    toast.error(errorHandler(e.data));
  };

  const oneDeleteOne = async (id: string) => {
    try {
      await api[endpoint].delete(`/${id}`);
      onDeleteComplete && onDeleteComplete(id);
      toast.success('Deleted successfully');
      refetchData();
    } catch (e: any) {
      refetchData();
      toast.error(e.data.message);
    }
  };

  const onSortChange = async (columnIdx: number, order: 'asc' | 'desc') => {
    const fieldName = String(columns[columnIdx].field);
    setTableState({...tableState, page: 0, sort:`${fieldName},${order.toUpperCase()}`});
  };

  const onPageChange = async (page: number) => {
    setTableState({...tableState, page});
  };

  const onRowsPerPageChange = async (limit: number) => {
    tableState.limit = limit;
    setTableState({...tableState});
  };

  const onGlobalSearch = async (searchText: string) => {
    if (!searchText) {
      setTableState({...tableState, limit: 10, page: 0, search: '{}'});
      return;
    }
    const searchParam = searchParmaBuilder(searchText, columns);
    setTableState({...tableState, limit: 200, page: 0, search: searchParam});
  };

  const getEditMethods = () => {
    const editMethods: Pick<MaterialTableProps<any>, 'editable'> = {};
    editMethods.editable = {};
    if (onRowAdd) {
      editMethods.editable.onRowAdd = (newData) => onRowAdd(newData);
    }
    if (onRowUpdate) {
      editMethods.editable.onRowUpdate = (newData, oldData) => onRowUpdate(newData, oldData);
    }

    if (isRowDelete) {
      editMethods.editable.onRowDelete = (oldData) =>
        new Promise((resolve) => {
          oneDeleteOne(oldData.id);
          resolve(null);
        });
    }
    return editMethods.editable;
  };

  return (
    <MaterialTable
      {...props}
      title={title}
      columns={columns}
      data={dataFetch?.data ? dataFetch.data : []}
      options={{
        rowStyle: {
          fontFamily: 'sans-serif',
        },
        sorting: true,
        thirdSortClick: false,
        selection: false,
        pageSizeOptions: [5, 10, 20],
        pageSize: tableState.limit,
        paging: true,
      }}
      onSearchChange={(value) => onGlobalSearch(value)}
      onOrderChange={(columnIdx, order) => onSortChange(columnIdx, order)}
      editable={getEditMethods()}
      page={dataFetch?.page ? dataFetch?.page - 1 : 0}
      totalCount={dataFetch?.total}
      onPageChange={(page) => onPageChange(page)}
      onRowsPerPageChange={(limit) => onRowsPerPageChange(limit)}
      components={{
        Pagination: (props) => {
          return (
            <td style={{display: 'flex', justifyContent: 'end'}}>
              <TablePagination component="div" {...props} />
            </td>
          );
        },
      }}
    />
  );
};
