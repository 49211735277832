import api, {apiOf} from './api';
import {ICountrySelect, IStateSelect, ICompany} from '@brix/shared-frontend';
import {AxiosResponse} from 'axios';

export const loadCountries = async (): Promise<ICountrySelect[]> => {
  const res = await api['/countries'].get('?limit=300&fields=id,name,iso3&sort=name,ASC');
  return res.data.data;
};

export const loadCompanies = async (): Promise<Array<ICompany>> => {
  return apiOf('/companies').get('/').then((response: AxiosResponse) => response.data.data);
};

export const loadStates = async (iso3: string): Promise<IStateSelect[]> => {
  const res = await api['/states'].get(`?filter=iso3||$cont||${iso3}&limit=300&fields=id,name,stateCode&sort=name,ASC`);
  return res.data.data;
};

export const getCountryName = (countries: ICountrySelect[] | undefined, citizenship: string): string => {
  if (!citizenship || !countries) {
    return '';
  }
  const country = countries.filter((c: ICountrySelect) => c.iso3 === citizenship);
  return country.length > 0 ? country[0].name : '';
};

export const getStateName = (stateList: IStateSelect[] | undefined, state: string): string => {
  if (!state || !stateList) {
    return '';
  }
  const stateName = stateList.filter((c: IStateSelect) => c.stateCode === state);
  return stateName.length > 0 ? stateName[0].name : '';
};

export const transformSelect = (countries: ICountrySelect[] | undefined, defaultOption: boolean): any => {
  if (!countries) {
    return [];
  }
  const countryList = countries.map((c: ICountrySelect) => ({
    value: c.iso3,
    label: c.name,
  }));

  if (defaultOption) {
    return [{value: '', label: 'Select Country'}, ...countryList];
  }
  return countryList;
};

export const transformSelectStates = (stateList: IStateSelect[] | undefined, defaultOption: boolean) => {

  const statesList = stateList?.map((c: IStateSelect) => ({
    value: c.stateCode,
    label: c.name,
  }));

  if (defaultOption) {
    return [{value: '', label: 'Select state'}, ...<[]>(statesList ?? [])];
  }
  return statesList;
};
