import {
  errorHandler,
  getPropertyStatusList,
  ICompany,
  setMultipleValues,
  useYupValidationResolver,
  TypeId,
  IPropertyDetails
} from '@brix/shared-frontend';
import { loadCompanies } from '@admin/utils';
import { Box, Button, Card, CardContent, Grid, MenuItem, TextField, Typography, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import * as Yup from 'yup';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import api from '@admin/utils/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { NumericFormat } from 'react-number-format';

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  grossValue: Yup.number()
    .typeError('Amount must be a number')
    .min(50000, 'Gross value must be more than 50K')
    .required('This field is required'),
  closingValue: Yup.number()
    .typeError('Amount must be a number')
    .min(50000, 'Closing value must be more than 50K')
    .required('This field is required'),
  hoa: Yup.number().typeError('Amount must be a number').required('This field is required'),
  arv: Yup.number().typeError('Amount must be a number').required('This field is required'),
  constructionYear: Yup.number()
    .typeError('Year must be a number')
    .test('len', 'Must be exactly 4 characters', (val) => val?.toString().length === 4)
    .max(new Date().getFullYear(), 'Year must be less than or equal to current year'),
  folioID: Yup.string().required('This field is required'),
  companyId: Yup.string().required('This field is required'),
  saleType: Yup.number().required('This field is required'),
  numberOfBathrooms: Yup.number()
    .typeError('Number of bathrooms must be a number')
    .min(1, 'Number of bathrooms must be greater than 0')
    .required('This field is required'),
  numberOfBedrooms: Yup.number()
    .typeError('Number of bedrooms must be a number')
    .min(1, 'Number of bedrooms must be greater than 0')
    .required('This field is required'),
  type: Yup.number().typeError('Type must be a valid number').required('This field is required'),
});

const updateProperty = async (propertyId: string, property: IPropertyDetails) => {
  const res = await api['/properties'].patch(`/${propertyId}`, property);
  return res.data;
};
const getBasic = async (propertyId: string) => {
  const fileds = 'name,grossValue,closingValue,hoa,arv,constructionYear,folioID,status,companyId,saleType,size,numberOfBathrooms,numberOfBedrooms,type';
  const { data } = await api['properties'].get(`/${propertyId}?fields=${fileds}`);
  return data;
};

export const PropertyBasic = (props: any) => {
  const resolver = useYupValidationResolver(validationSchema);
  const { setPropertyName, tabSelected } = props
  const { state } = useLocation<IPropertyDetails>();
  const { id } = useParams<TypeId>();
  const queryClient = useQueryClient();
  const [companiesList, setCompaniesList] = useState<ICompany[]>([])
  const [numberOfBathrooms, setNumberOfBathrooms] = useState(0)
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(0)

  const handleBathroomsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) % 1 === 0) {
      setValue('numberOfBathrooms', Number(Number(event.target.value).toFixed(0)));
      setNumberOfBathrooms(Number(Number(event.target.value).toFixed(0)));
    } else {
      setValue('numberOfBathrooms', Number(event.target.value));
      setNumberOfBathrooms(Number(event.target.value));
    }
  };

  const handleBedroomsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) % 1 === 0) {
      setValue('numberOfBedrooms', Number(Number(event.target.value).toFixed(0)));
      setNumberOfBedrooms(Number(Number(event.target.value).toFixed(0)));
    } else {
      setValue('numberOfBedrooms', Number(event.target.value));
      setNumberOfBedrooms(Number(event.target.value));
    }
  };


  const { register, handleSubmit, formState, setValue, reset } = useForm<IPropertyDetails>({
    resolver,
    mode: 'onChange',
  });
  const { errors } = formState;

  const { isLoading, data: companies } = useQuery(['companies'], () => api['/companies'].get(`/`));

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (companies) {
      setCompaniesList(companies.data.data)
    }
  }, [companies])

  const { data: basicData, isLoading: isBasicLoading, refetch: refetchBasics } = useQuery<IPropertyDetails, any>(
    ['property', id],
    () => getBasic(id),
    {
      onSuccess: (basics: IPropertyDetails) => {
        setMultipleValues(setValue, basics);
        setNumberOfBathrooms(Number(basics.numberOfBathrooms))
        setNumberOfBedrooms(Number(basics.numberOfBedrooms))
      },
      onError: (err: any) => {
        toast.error(errorHandler(err.data));
      },
    }
  );

  useEffect(() => {

    if (basicData) {
      setMultipleValues(setValue, basicData);
      setValue('numberOfBathrooms', Number(basicData.numberOfBathrooms))
      setValue('numberOfBedrooms', Number(basicData.numberOfBedrooms))
      setPropertyName(basicData.name);
    }

  }, [basicData]);

  useEffect(() => {
    tabSelected === 1 && refetchBasics();
  }, [tabSelected])

  const mutation = useMutation((data: IPropertyDetails) => updateProperty(id, data), {
    onSuccess: () => {
      toast.success('Property updated successfully');
      queryClient.resetQueries('properties');
      refetchBasics()
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const onSaveProperty = (property: IPropertyDetails) => {
    property.hoa = property.hoa.toString();
    mutation.mutate(property);
  };


  if (isBasicLoading) {
    return (
      <Box justifyContent="center" display="flex">
        <CircularProgress />
      </Box>
    )
  }

  else {
    return (
      <form onSubmit={handleSubmit(onSaveProperty)}>
        <Card>
          <CardContent>
            <Box>
              <Typography variant="h6">Property Basic Details</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                  error={Boolean(errors.name?.message)}
                  fullWidth
                  helperText={errors.name?.message}
                  label="Name"
                  sx={{ mt: 2 }}
                  {...register('name')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  error={Boolean(errors.grossValue?.message)}
                  fullWidth
                  helperText={errors.grossValue?.message}
                  onValueChange={(v) => setValue('grossValue', Number(v.floatValue))}
                  decimalScale={2}
                  prefix="$"
                  label="Gross Value"
                  sx={{ mt: 2 }}
                  value={basicData?.grossValue}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  error={Boolean(errors.closingValue?.message)}
                  fullWidth
                  helperText={errors.closingValue?.message}
                  onValueChange={(v) => setValue('closingValue', Number(v.floatValue))}
                  decimalScale={2}
                  prefix="$"
                  label="Closing Value"
                  sx={{ mt: 2 }}
                  value={basicData?.closingValue}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  error={Boolean(errors.hoa?.message)}
                  fullWidth
                  helperText={errors.hoa?.message}
                  onValueChange={(v) => setValue('hoa', Number(v.floatValue))}
                  decimalScale={2}
                  prefix="$"
                  label="HOA"
                  sx={{ mt: 2 }}
                  value={Number(basicData?.hoa)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  error={Boolean(errors.arv?.message)}
                  fullWidth
                  helperText={errors.arv?.message}
                  onValueChange={(v) => setValue('arv', Number(v.floatValue))}
                  decimalScale={2}
                  prefix="$"
                  label="ARV"
                  sx={{ mt: 2 }}
                  value={basicData?.arv}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(errors.constructionYear?.message)}
                  fullWidth
                  helperText={errors.constructionYear?.message}
                  label="Year Built"
                  sx={{ mt: 2 }}
                  {...register('constructionYear')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(errors.numberOfBathrooms?.message)}
                  helperText={errors.numberOfBathrooms?.message}
                  label="N° of Bathrooms"
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      step: 0.5,
                    },
                  }}
                  sx={{
                    mt: 2,
                  }}
                  onChange={handleBathroomsChange}
                  value={numberOfBathrooms}
                />
               
              </Grid>
              <Grid item xs={12} md={6}>
              <TextField
                  error={Boolean(errors.numberOfBedrooms?.message)}
                  helperText={errors.numberOfBedrooms?.message}
                  label="N° of Bedrooms"
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      step: 0.5,
                    },
                  }}
                  sx={{
                    mt: 2,
                  }}
                  onChange={handleBedroomsChange}
                  value={numberOfBedrooms}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(errors.folioID?.message)}
                  helperText={errors.folioID?.message}
                  label="Folio ID"
                  fullWidth
                  sx={{ mt: 2 }}
                  {...register('folioID')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Status"
                  select
                  id="status"
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                  error={Boolean(errors.status?.message)}
                  {...register('status')}
                  defaultValue={Number(basicData?.status)}
                >
                  {getPropertyStatusList().map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Company"
                  select
                  id="companyId"
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                  error={Boolean(errors.companyId?.message)}
                  {...register('companyId')}
                  defaultValue={basicData?.companyId}
                >
                  {companiesList ? companiesList.map((company: ICompany, id: number) => (
                    <MenuItem key={id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  )) :
                    <MenuItem>Loading...</MenuItem>}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  select
                  label="Type"
                  id="type"
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                  error={Boolean(errors.type?.message)}
                  {...register('type')}
                  defaultValue={basicData?.type}
                >
                  <MenuItem value="1">
                    Apartment
                  </MenuItem>
                  <MenuItem value="2">
                    Condo
                  </MenuItem>
                  <MenuItem value="3">
                    Town House
                  </MenuItem>
                  <MenuItem value="4">
                    Single-family
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  select
                  label="Sale Type"
                  id="saleType"
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                  error={Boolean(errors.saleType?.message)}
                  {...register('saleType')}
                  defaultValue={basicData?.saleType}
                >
                  <MenuItem value="0">
                    Pre-sale
                  </MenuItem>
                  <MenuItem value="1">
                    Offered
                  </MenuItem>
                  <MenuItem value="2">
                    Open-market
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={3} pt={4}>
              <Grid item>
                <Button type="submit" disabled={formState.isSubmitting} variant="outlined">
                  Save Property
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    );
  }
};
