import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, {createContext, useEffect, useReducer} from 'react';
import axiosInstance from '../utils/axios';
import {localStorage, IUser, IToken, IJWTDecoder} from '@brix/shared-frontend';
import {UserRole} from '@brix/shared-frontend';

interface IAuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: IUser | null;
}

const initialAuthState: IAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;
  return decodeJWT(accessToken).exp > currentTime;
};
const decodeJWT = (accessToken: string) => {
  return jwt_decode<IJWTDecoder>(accessToken);
};

const setSession = (accessTokens: IToken | null) => {
  if (accessTokens) {
    localStorage.set('accessTokens', JSON.stringify(accessTokens));
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessTokens.access.token}`;
  } else {
    localStorage.rm('accessTokens');
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const {isAuthenticated, user} = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'UPDATE_USER': {
      const {user} = action.payload;
      return {...state, user};
    }
    case 'LOGIN': {
      const {user} = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return {...state};
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: (email: string, password: string) => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: (email: string) => {},
});

export const AuthProvider = ({children}: {children: React.ReactElement}) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string) => {
    const response = await axiosInstance.post(
      '/auth/login',
      {email, password},
      {
        data: {skipAuthRefresh: true},
      }
    );
    const {tokens, user} = response.data;
    if (!user.roles.includes(UserRole.Admin) && !user.roles.includes(UserRole.SuperAdmin)) {
      throw new Error('Wrong credentials');
    }
    setSession(tokens);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = async (email: string) => {
    const response = await axiosInstance.post(
      'auth/logout',
      {email},
      {
        data: {skipAuthRefresh: true},
      }
    );
    setSession(null);
    dispatch({type: 'LOGOUT'});
  };

  const updateAuthUser = (user: IUser) => {
    dispatch({type: 'UPDATE_USER', payload: {user}});
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const tokens: IToken = JSON.parse(localStorage.get('accessTokens'));
        if (tokens && isValidToken(tokens.access.token)) {
          setSession(tokens);

          const response = await axiosInstance.get(`/me`);
          const user = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // TODO: add splash screen
  // if (!state.isInitialized) {
  //   return <SplashScreen />;
  // }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        updateAuthUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
