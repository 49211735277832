import { PropertyListItem } from '@admin/components/common';
import { useForm } from 'react-hook-form';
import { CardHeader } from '@admin/components/common';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useYupValidationResolver, errorHandler, getCountryIso3 } from '@brix/shared-frontend';
import { toast } from 'react-toastify';
import { Card, Divider, List, Box, CircularProgress, Typography, Button } from '@mui/material';
import { api, loadCountries, loadStates, transformSelect, getCountryName, transformSelectStates, getStateName } from '@admin/utils';
import { useMutation, useQueryClient, useQuery } from 'react-query';

const validationSchema = yup.object({
  street: yup.string().required('This field is required'),
  street2: yup.string().nullable(),
  zipCode: yup.string().min(3).max(16).required('This field is required'),
  city: yup.string().required('This field is required'),
  stateCode: yup.string().required('This field is required'),
});

const loadAddress = async (userId: string) => {
  const res = await api['users'].get(`/${userId}/address`);
  return res.data;
}

const updateAddress = async (propertyId: string, address: AddressProps) => {
  const { data } = await api['users'].patch(`/${propertyId}/address`, address);
  return data;
};

interface AddressProps {
  street: string;
  street2?: string;
  zipCode?: string;
  countryId?: string;
  city?: string;
  stateCode?: string;
  iso3?: string;
}
export const UserAddress = (props: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState('');
  const resolver = useYupValidationResolver(validationSchema);
  const { id } = useParams<{ id: string }>();
  const [countryCode, setCountryCode] = useState('USA');
  const [editMode, setEditMode] = useState(false)

  const { data: userAddress, error: isAddressError, isLoading: isUserAddressLoading, refetch } = useQuery(['users', { id }], () => loadAddress(id), {
    keepPreviousData: true,
  });

  const { data: countries } = useQuery('countries', loadCountries);

  useEffect(() => {
    if (isAddressError) {
      toast.error(errorHandler(isAddressError));
    }

  }, [isAddressError]);

  useEffect(() => {

    if (isEditing) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }

  }, [isEditing])
  

  useEffect(() => {
    if (userAddress) {
      setAddress(userAddress);
      setCountryCode(userAddress.countryId);
    }
  }, [userAddress])

  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useForm<AddressProps>({ resolver, mode: 'onChange' });

  const { errors } = formState;

  const watchCountry = watch('countryId');

  useEffect(() => {
    if (watchCountry) {
      setCountryCode(watchCountry)
    }
  }, [watchCountry])


  const { data: stateList, isLoading: isStatesLoading } = useQuery(['states', { countryCode }], () => loadStates(countryCode));

  const mutationUpdate = useMutation((data: AddressProps) => updateAddress(id, data), {
    onSuccess: (res: any) => {
      const { data: newAddress } = res;
      setAddress(newAddress);
      refetch();
      toast.success('Address updated successfully');
      setIsEditing(!isEditing)
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const onSubmit = async (data: AddressProps) => {
    mutationUpdate.mutate(data);
  };

  if (isUserAddressLoading || isStatesLoading) {
    return (
      <Box justifyContent="center" display="flex">
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              py: 3,
              px: 3,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box sx={{}}>
              <Typography variant="h6">Address</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'flex-end',
            }}>
            <Button
              color="primary"
              variant={isEditing ? 'contained' : 'outlined'}
              onClick={() => setIsEditing(!isEditing)}
              disabled={formState.isSubmitting}
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
            {
              isEditing ?
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={formState.isSubmitting}
              >
                Save
              </Button>
              : null
            }
            </Box>
          </Box>
          <Divider />
          {address && (
            <List disablePadding>
              <PropertyListItem
                name="street"
                itemType="input"
                align={'left'}
                divider
                label="Address"
                value={userAddress.street}
                isEdit={isEditing}
                control={control}
                inputProps={{
                  type: 'text',
                  placeholder: 'Please enter address',
                }}
                error={errors?.street?.message}
              />
              <PropertyListItem
                name="street2"
                itemType="input"
                align={'left'}
                divider
                label="Address 2"
                value={userAddress.street2}
                isEdit={isEditing}
                control={control}
                inputProps={{
                  type: 'text',
                  placeholder: 'Please enter address 2',
                }}
                error={errors?.street2?.message}
              />
              <PropertyListItem
                name="zipCode"
                itemType="input"
                align={'left'}
                divider
                label="Zip Code"
                value={userAddress.zipCode}
                isEdit={isEditing}
                control={control}
                inputProps={{
                  type: 'text',
                  placeholder: 'Please enter zip code',
                }}
                error={errors?.zipCode?.message}
              />
              <PropertyListItem
                name="city"
                itemType="input"
                align={'left'}
                divider
                label="City"
                value={userAddress.city}
                isEdit={isEditing}
                control={control}
                inputProps={{
                  type: 'text',
                  placeholder: 'Please enter city',
                }}
                error={errors?.city?.message}
              />
              <PropertyListItem
                name="countryId"
                itemType="select"
                align={'left'}
                divider
                label="Country"
                value={isEditing ? userAddress?.countryId : getCountryName(countries, userAddress?.countryId)}
                control={control}
                isEdit={isEditing}
                selectProps={{
                  options: transformSelect(countries, Boolean(!userAddress?.countryId)),
                }}
                error={errors?.countryId?.message}
              />
              <PropertyListItem
                name="stateCode"
                itemType="select"
                align={'left'}
                divider
                label="State"
                value={isEditing ? userAddress?.stateCode : getStateName(stateList, userAddress?.stateCode)}
                isEdit={isEditing}
                control={control}
                selectProps={{
                  options: transformSelectStates(stateList, Boolean(!userAddress?.stateCode)),
                }}
                error={errors?.stateCode?.message}
              />
            </List>
          )}
        </form>
      </Card>
    );
  }
};

