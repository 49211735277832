import {createContext, FunctionComponent, ReactElement, useContext} from 'react';
import useLocales from '@admin/hooks/useLocales';
import {AvailableLocale} from '@brix/shared-frontend';

export type LocaleProviderParam = { children: ReactElement };
export type LocaleProviderValue = { locales: Array<AvailableLocale> };

export const LocaleContext = createContext<LocaleProviderValue>({locales: []});

export const LocaleProvider: FunctionComponent<LocaleProviderParam> = ({children}: LocaleProviderParam) => {
  const {locales} = useLocales();
  return (
    <LocaleContext.Provider value={{locales}}>
      {children}
    </LocaleContext.Provider>
  );
}

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};

export const LocaleConsumer = LocaleContext.Consumer;
