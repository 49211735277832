import {Box, Container} from '@mui/material';
import React, {useEffect} from 'react';
import {Column} from '@material-table/core';
import {BrixTable} from '@admin/components/common';
import {validator, errorHandler, IPage, AddingStyleHeader} from '@brix/shared-frontend';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {api} from '@admin/utils';
import {IQuestion} from './interfaces/security-questions';
import {useMutation, useQueryClient} from 'react-query';
import moment from 'moment';

const columns: Column<any>[] = [
  {
    title: 'Question',
    field: 'question',
    type: 'string',
    customSort: (a, b) => a.question.toLowerCase() - b.question.toLowerCase(),
    validate: validator(
      Yup.object().shape({
        question: Yup.string().min(10).max(255).required('Question is required'),
      })
    ),
  },
  {
    title: 'Created At',
    field: 'createdAt',
    type: 'date',
    filtering: false,
    editable: 'never',
    render: (data) => <span>{moment(data.createdAt).format('MM-DD-YYYY')}</span>,
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    type: 'date',
    filtering: false,
    editable: 'never',
    render: (data) => <span>{moment(data.updatedAt).format('MM-DD-YYYY')}</span>,
  },
];

const createQuestion = async (data: IQuestion) => {
  const res = await api['/security-question'].post('', data);
  return res.data;
};

const updateQuestion = async (id: string, data: IQuestion) => {
  const res = await api['/security-question'].patch(`/${id}`, data);
  return res.data;
};

const SecurityQuestionView: React.FunctionComponent<IPage> = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    document.title = 'Security Questions';
  }, []);

  const mutationCreate = useMutation((data: IQuestion) => createQuestion(data), {
    onSuccess: () => {
      toast.success('Question has been created successfully');
      queryClient.resetQueries('security-question');
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  const mutationUpdate = useMutation(({id, data}: {id: string; data: IQuestion}) => updateQuestion(id, data), {
    onSuccess: () => {
      toast.success('Question has been updated successfully');
      queryClient.resetQueries('security-question');
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
    },
  });

  return (
    <Box
      component="main"
      sx={{flexGrow: 1, py: 3, backgroundColor: 'background.default', height: '100%', minHeight: '100vh'}}
    >
      <Container maxWidth="xl">
        <Box sx={{mb: 4}}>
          <BrixTable
            title="Security Questions"
            columns={AddingStyleHeader(columns)}
            isRowDelete={true}
            endpoint="security-question"
            onRowAdd={(newData, fetchCallback) => {
              return new Promise((resolve) => {
                mutationCreate.mutate(newData);
                fetchCallback && fetchCallback();
                resolve(null);
              });
            }}
            onRowUpdate={(newData, oldData, fetchCallback) => {
              return new Promise((resolve) => {
                mutationUpdate.mutate({id: oldData.id, data: newData});
                fetchCallback && fetchCallback();
                resolve(null);
              });
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default SecurityQuestionView;
