import { Button, Card, CardContent, Grid, Typography, Switch, FormControlLabel, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import api from '@admin/utils/api';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { errorHandler, TypeId, IPropertyPublishDetails } from '@brix/shared-frontend';
import { Link as RouterLink } from 'react-router-dom';
import BrixAvailableModule from './BrixAvailableModule';

const getProperty = async (propertyId: string) => {
  const { data } = await api['properties'].get(`/${propertyId}`);
  return data;
};

const Publishing = () => {
  const { id } = useParams<TypeId>();

  const { data: propertyData, refetch: refetchData } = useQuery<IPropertyPublishDetails, IPropertyPublishDetails>(
    ['properties', 'publish', id],
    () => getProperty(id),
    {
      onError: (err: any) => {
        toast.error(errorHandler(err.data));
      },
    }
  );

  const handleToggleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isPropertyPublic = event.target.checked;

    try {
      await api['/properties'].patch(`/${propertyData?.id}`, { isPropertyPublic: isPropertyPublic });
      refetchData();
      toast.success(`Property ${isPropertyPublic ? 'published' : 'unpublished'} successfully on marketing site.`);
    } catch (error) {
      toast.error(`Error ${isPropertyPublic ? 'publishing' : 'unpublishing'} the property. Please try again later.`);
    }
  };

  const publishHandler = () => {
    api['/properties'].post('/publish', { propertyId: propertyData?.id })
      .then(() => {
        toast.success('Property published successfully');
        refetchData();
      }).catch(() => {
        toast.error('Error publishing the selected property. Please try again later.');
        refetchData();
      });
  };

  const unpublishHandler = () => {
    api['/properties'].post('/unpublish', { propertyId: propertyData?.id })
      .then(() => {
        toast.success('Property unpublished successfully');
        refetchData();
      }).catch(() => {
        toast.error('Error unpublishing the selected property. Please try again later.');
        refetchData();
      });
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Typography variant="h5">Publish the property when all the information is complete</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="primary" onClick={publishHandler} disabled={!!propertyData?.publishedAt} sx={{
                  width: '100%',
                  height: '100%',
                }}>
                  Publish
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">Hide property from marketplace</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button variant="outlined" color="primary" onClick={unpublishHandler} disabled={!propertyData?.publishedAt}
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  Unpublish
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">Preview property for marketplace</Typography>
              </Grid>
              <Grid item xs={3}>
                <RouterLink to={'/preview/' + id} style={{ textDecoration: 'initial' }}>
                  <Button fullWidth color="primary" variant="contained" sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                    Preview
                  </Button>
                </RouterLink>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">Publish property in marketing site</Typography>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  flexDirection: 'row',

                }}>
                  <Typography variant="h6">Private</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!propertyData?.isPropertyPublic}
                        onChange={handleToggleChange}
                        color="primary"
                        size='medium'
                      />
                    }
                    label={''}
                  />
                  <Typography variant="h6">Public</Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <BrixAvailableModule
                closingValue={Number(propertyData?.closingValue)}
                refetchData={refetchData}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Publishing;
