import {Box, Container} from '@mui/material';
import React, {useEffect} from 'react';
import {BrixTable} from '@admin/components/common';
import {Column} from '@material-table/core';
import {getPropertyStatus, PropertyStatusTypes, IPage, PropertyStatusPublishedTypes, PropertyTypes} from '@brix/shared-frontend';
import {useHistory} from 'react-router';

const columns: Column<any>[] = [
  {
    title: 'Name',
    field: 'name',
    customSort: (a, b) => a.name.toLowerCase() - b.name.toLowerCase(),
    type: 'string',
  },
  {
    title: 'Type',
    field: 'type',
    type: 'string',
    render: (rowData) => {
      return PropertyTypes[rowData.type];
    }
  },
  {
    title: 'Date created',
    field: 'createdAt',
    type: 'date',
  },
  {
    title: 'Status',
    field: 'status',
    type: 'string',
    render: (rowData) => getPropertyStatus(rowData.status),
    initialEditValue: PropertyStatusTypes.pending,
  },
  {
    title: 'Publishing',
    field: 'publishedAt',
    type: 'string',
    render: (rowData) => rowData.publishedAt === null ? 'Not Published' : 'Published',
    initialEditValue: PropertyStatusPublishedTypes.unpublished,
  },
];

const PropertiesListView: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = 'Properties';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{mb: 4}}>
          <BrixTable
            endpoint="properties"
            title="Properties"
            columns={columns}
            onRowClick={(_, row) => history.push(`/properties/${row.id}`, row)}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PropertiesListView;
