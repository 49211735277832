import {UserRole} from '../types';
import {Column} from '@material-table/core';

export const filterParamBuilder = (filters: Array<any>) => {
  let param = '';
  filters.forEach((d) => {
    param += `filter=${d.column.field}||$cont||${d.value}&`;
  });
  return param;
};

export const validator = (schema: any) => (values: any) => {
  try {
    schema.validateSync(values, {stripUnknown: true});
    return {isValid: true, helperText: ''};
  } catch (e: any) {
    return {isValid: false, helperText: e.message};
  }
};

export const searchParmaBuilder = (searchText: string, columns: Column<any>[]) => {
  const searchList: any = [];
  columns.forEach((curr) => {
    if (curr.type !== 'date') {
      const fieldName = String(curr.field);
      searchList.push({[fieldName]: {$cont: searchText}});
    }
  });

  return JSON.stringify({$or: searchList});
};

export const getAdminRoles = () => {
  const roles: Array<string> = [];
  Object.values(UserRole).map((role: any) => {
    if (role !== UserRole.User && role !== UserRole.Investor) {
      roles.push(role);
    }
  });
  return roles;
};
