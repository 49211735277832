import {
    errorHandler,
    IPage,
    useYupValidationResolver,
    IFeesDetails,
    IFeesObject,
} from '@brix/shared-frontend';
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import api from '@admin/utils/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import Switch from '@mui/material/Switch';


const updateFees = async (fees: IFeesDetails[]) => {
    const res = await api['fees'].post(`/multiple`, fees);
    return res.data;
};

const createFees = async (fees: IFeesDetails[]) => {
    const res = await api['fees'].post(`/multiple`, fees);
    return res.data;
};

const getFees = async () => {
    const { data } = await api['fees'].get(`/`);
    return data.data;
};

const validationSchema = Yup.object({
    buyBrix: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    buyStax: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    buyBrixie: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    sellBrix: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    sellStax: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    sellBrixie: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    transferFee: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    brixieToBrix: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    brixToStax: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    staxToBrix: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    brixToBrixie: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    upfrontMembership: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
    fractionalized: Yup.object().shape({
        amount: Yup.number()
            .typeError('Amount must be a number'),
        isPercentage: Yup.boolean()
    }),
});


const PaymentFees: React.FunctionComponent<IPage> = () => {
    const [dataFees, setDataFees] = useState<IFeesObject>({})
    const [buyBrixPercentage, setBuyBrixPercentage] = useState<boolean>(false)
    const [buyStaxPercentage, setBuyStaxPercentage] = useState<boolean>(false)
    const [buyBrixiePercentage, setBuyBrixiePercentage] = useState<boolean>(false)
    const [sellBrixPercentage, setSellBrixPercentage] = useState<boolean>(false)
    const [sellStaxPercentage, setSellStaxPercentage] = useState<boolean>(false)
    const [sellBrixiePercentage, setSellBrixiePercentage] = useState<boolean>(false)
    const [brixieToBrixPercentage, setBrixieToBrixPercentage] = useState<boolean>(false)
    const [brixToStaxPercentage, setBrixToStaxPercentage] = useState<boolean>(false)
    const [staxToBrixPercentage, setStaxToBrixPercentage] = useState<boolean>(false)
    const [brixToBrixiePercentage, setBrixToBrixiePercentage] = useState<boolean>(false)
    const [transferFeeisPercentage, setTransferFeeisPercentage] = useState<boolean>(false)
    const [upfrontMembershipisPercentage, setUpfrontMembershipisPercentage] = useState<boolean>(false)
    const [fractionalizedisPercentage, setFractionalizedisPercentage] = useState<boolean>(false)

    const handlePercentageChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {

        if (field === 'buyBrix.isPercentage') {
            setValue('buyBrix.isPercentage', event.target.checked)
            setBuyBrixPercentage(event.target.checked)
        } else if (field === 'buyStax.isPercentage') {
            setValue('buyStax.isPercentage', event.target.checked)
            setBuyStaxPercentage(event.target.checked)
        }
        else if (field === 'buyBrixie.isPercentage') {
            setValue('buyBrixie.isPercentage', event.target.checked)
            setBuyBrixiePercentage(event.target.checked)
        }
        else if (field === 'sellBrix.isPercentage') {
            setValue('sellBrix.isPercentage', event.target.checked)
            setSellBrixPercentage(event.target.checked)
        } else if (field === 'sellStax.isPercentage') {
            setValue('sellStax.isPercentage', event.target.checked)
            setSellStaxPercentage(event.target.checked)
        } else if (field === 'sellBrixie.isPercentage') {
            setValue('sellBrixie.isPercentage', event.target.checked)
            setSellBrixiePercentage(event.target.checked)
        } else if (field === 'brixieToBrix.isPercentage') {
            setValue('brixieToBrix.isPercentage', event.target.checked)
            setBrixieToBrixPercentage(event.target.checked)
        } else if (field === 'brixToStax.isPercentage') {
            setValue('brixToStax.isPercentage', event.target.checked)
            setBrixToStaxPercentage(event.target.checked)
        } else if (field === 'staxToBrix.isPercentage') {
            setValue('staxToBrix.isPercentage', event.target.checked)
            setStaxToBrixPercentage(event.target.checked)
        } else if (field === 'brixToBrixie.isPercentage') {
            setValue('brixToBrixie.isPercentage', event.target.checked)
            setBrixToBrixiePercentage(event.target.checked)
        } else if (field === 'transferFee.isPercentage') {
            setValue('transferFee.isPercentage', event.target.checked)
            setTransferFeeisPercentage(event.target.checked)
        }
        else if (field === 'upfrontMembership.isPercentage') {
            setValue('upfrontMembership.isPercentage', event.target.checked)
            setUpfrontMembershipisPercentage(event.target.checked)
        }
        else if (field === 'fractionalized.isPercentage') {
            setValue('fractionalized.isPercentage', event.target.checked)
            setFractionalizedisPercentage(event.target.checked)
        }

    }

    const resolver = useYupValidationResolver(validationSchema);
    const queryClient = useQueryClient();

    const { handleSubmit, formState, setValue, watch } = useForm<any>({
        resolver,
        mode: 'onChange',
    });
    const { errors } = formState;

    const mutation = useMutation((data: IFeesDetails[]) => updateFees(data), {
        onSuccess: () => {
            toast.success('Fees updated successfully');
            queryClient.resetQueries(['fees']);
        },
        onError: (err: any) => {
            toast.error(errorHandler(err.data));
        },
    });

    const mutationCreate = useMutation((data: IFeesDetails[]) => createFees(data), {
        onSuccess: async (res: IFeesDetails) => {
            queryClient.resetQueries('fees');
            toast.success(`Fees have been updated successfully`);
        },
        onError: (err: any) => {
            toast.error(errorHandler(err.data));
        },
    });

    const { data: feesData, isLoading: isFeesLoading } = useQuery<IFeesDetails[], IFeesDetails[]>(
        ['fees'],
        () => getFees(),
        {
            onError: (err: any) => {
                toast.error(errorHandler(err.data));
            },
        }
    );

    useEffect(() => {

        if (feesData) {
            const data = feesData.reduce((acc: IFeesObject, item: IFeesDetails) => {
                acc[item.type] = item
                return acc
            }, {})
            setDataFees(data)

            feesData.map((fee: IFeesDetails) => {

                if (fee.type === 'buyBrix') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setBuyBrixPercentage(Boolean(fee.isPercentage))
                } else if (fee.type === 'buyStax') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setBuyStaxPercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'buyBrixie') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setBuyBrixiePercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'sellBrix') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setSellBrixPercentage(Boolean(fee.isPercentage))
                } else if (fee.type === 'sellStax') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setSellStaxPercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'sellBrixie') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setSellBrixiePercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'brixieToBrix') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setBrixieToBrixPercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'brixToStax') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setBrixToStaxPercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'staxToBrix') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setStaxToBrixPercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'brixToBrixie') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setBrixToBrixiePercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'upfrontMembership') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setUpfrontMembershipisPercentage(Boolean(fee.isPercentage))
                } 
                else if (fee.type === 'transferFee') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setTransferFeeisPercentage(Boolean(fee.isPercentage))
                }
                else if (fee.type === 'fractionalized') {
                    setValue(`${fee.type}.isPercentage`, fee.isPercentage)
                    return setFractionalizedisPercentage(Boolean(fee.isPercentage))
                }
            }
            )
        }
    }, [feesData])

    const onSaveFees = (fees: IFeesDetails) => {

            const feesDataArray: IFeesDetails[] = []

            for (const [key, value] of Object.entries(fees)) {
                feesDataArray.push({
                    type: key,
                    amount: value.amount ? Number(value.amount) : 0,
                    isPercentage: value.isPercentage ? value.isPercentage : false
                })
            }
            mutationCreate.mutate(feesDataArray);

    };

    return (

        <form onSubmit={handleSubmit(onSaveFees)}>
            <Card>
                <CardContent>
                    <Box>
                        <Typography variant="h6">Management Payment Fees</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container spacing={4} >
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.buyStax?.message)}
                                        fullWidth
                                        helperText={errors.buyStax?.message}
                                        onValueChange={(v) => {
                                            if (v.floatValue) {
                                              setValue('buyStax.amount', v.floatValue);
                                            } else {
                                              setValue('buyStax.amount', 0);
                                            }
                                          }}
                                        decimalScale={2}
                                        prefix={buyStaxPercentage ? '' : '$'}
                                        suffix={buyStaxPercentage ? '%' : ''}
                                        label="Buy Stax Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['buyStax']?.amount ? dataFees?.['buyStax']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (buyStaxPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            checked={buyStaxPercentage}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'buyStax.isPercentage')}
                                            size='medium'
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4} >
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.buyBrix?.message)}
                                        fullWidth
                                        helperText={errors.buyBrix?.message}
                                        onValueChange={(v) => {
                                            if (v.floatValue) {
                                              setValue('buyBrix.amount', v.floatValue);
                                            } else {
                                              setValue('buyBrix.amount', 0);
                                            }
                                          }}
                                        decimalScale={2}
                                        prefix={buyBrixPercentage ? '' : '$'}
                                        suffix={buyBrixPercentage ? '%' : ''}
                                        label="Buy BRiX Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['buyBrix']?.amount ? dataFees?.['buyBrix']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (buyBrixPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            checked={buyBrixPercentage}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'buyBrix.isPercentage')}
                                            size='medium'
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4} >
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.buyBrixie?.message)}
                                        fullWidth
                                        helperText={errors.buyBrixie?.message}
                                        onValueChange={(v) => setValue('buyBrixie.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={buyBrixiePercentage ? '' : '$'}
                                        suffix={buyBrixiePercentage ? '%' : ''}
                                        label="Buy BRiXie Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['buyBrixie']?.amount ? dataFees?.['buyBrixie']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (buyBrixiePercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            checked={buyBrixiePercentage}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'buyBrixie.isPercentage')}
                                            size='medium'
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.sellStax?.message)}
                                        fullWidth
                                        helperText={errors.sellStax?.message}
                                        onValueChange={(v) => setValue('sellStax.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={sellStaxPercentage ? '' : '$'}
                                        suffix={sellStaxPercentage ? '%' : ''}
                                        label="Sell Stax Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['sellStax']?.amount ? dataFees?.['sellStax']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (sellStaxPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'sellStax.isPercentage')}
                                            size='medium'
                                            checked={sellStaxPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.sellBrix?.message)}
                                        fullWidth
                                        helperText={errors.sellBrix?.message}
                                        onValueChange={(v) => setValue('sellBrix.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={sellBrixPercentage ? '' : '$'}
                                        suffix={sellBrixPercentage ? '%' : ''}
                                        label="Sell BRiX Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['sellBrix']?.amount ? dataFees?.['sellBrix']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (sellBrixPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'sellBrix.isPercentage')}
                                            size='medium'
                                            checked={sellBrixPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.sellBrixie?.message)}
                                        fullWidth
                                        helperText={errors.sellBrixie?.message}
                                        onValueChange={(v) => setValue('sellBrixie.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={sellBrixiePercentage ? '' : '$'}
                                        suffix={sellBrixiePercentage ? '%' : ''}
                                        label="Sell BRiXie Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['sellBrixie']?.amount ? dataFees?.['sellBrixie']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (sellBrixiePercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'sellBrixie.isPercentage')}
                                            size='medium'
                                            checked={sellBrixiePercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.transferFee?.message)}
                                        fullWidth
                                        helperText={errors.transferFee?.message}
                                        onValueChange={(v) => setValue('transferFee.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={transferFeeisPercentage ? '' : '$'}
                                        suffix={transferFeeisPercentage ? '%' : ''}
                                        label="Transfer Fee from/to financials from/to personal bank account"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['transferFee']?.amount ? dataFees?.['transferFee']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (transferFeeisPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'transferFee.isPercentage')}
                                            size='medium'
                                            checked={transferFeeisPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.brixieToBrix?.message)}
                                        fullWidth
                                        helperText={errors.brixieToBrix?.message}
                                        onValueChange={(v) => setValue('brixieToBrix.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={brixieToBrixPercentage ? '' : '$'}
                                        suffix={brixieToBrixPercentage ? '%' : ''}
                                        label="Assemble BRiXie to BRiX Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['brixieToBrix']?.amount ? dataFees?.['brixieToBrix']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (brixieToBrixPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'brixieToBrix.isPercentage')}
                                            size='medium'
                                            checked={brixieToBrixPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.brixToStax?.message)}
                                        fullWidth
                                        helperText={errors.brixToStax?.message}
                                        onValueChange={(v) => setValue('brixToStax.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={brixToStaxPercentage ? '' : '$'}
                                        suffix={brixToStaxPercentage ? '%' : ''}
                                        label="Assemble BRiX to Stax Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['brixToStax']?.amount ? dataFees?.['brixToStax']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (brixToStaxPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'brixToStax.isPercentage')}
                                            size='medium'
                                            checked={brixToStaxPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.staxToBrix?.message)}
                                        fullWidth
                                        helperText={errors.staxToBrix?.message}
                                        onValueChange={(v) => setValue('staxToBrix.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={staxToBrixPercentage ? '' : '$'}
                                        suffix={staxToBrixPercentage ? '%' : ''}
                                        label="Disassemble Stax to BRiX Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['staxToBrix']?.amount ? dataFees?.['staxToBrix']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (staxToBrixPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'staxToBrix.isPercentage')}
                                            size='medium'
                                            checked={staxToBrixPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.brixToBrixie?.message)}
                                        fullWidth
                                        helperText={errors.brixToBrixie?.message}
                                        onValueChange={(v) => setValue('brixToBrixie.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={brixToBrixiePercentage ? '' : '$'}
                                        suffix={brixToBrixiePercentage ? '%' : ''}
                                        label="Disassemble BRiX to BRiXie Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['brixToBrixie']?.amount ? dataFees?.['brixToBrixie']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (brixToBrixiePercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'brixToBrixie.isPercentage')}
                                            size='medium'
                                            checked={brixToBrixiePercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.upfrontMembership?.message)}
                                        fullWidth
                                        helperText={errors.upfrontMembership?.message}
                                        onValueChange={(v) => setValue('upfrontMembership.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={upfrontMembershipisPercentage ? '' : '$'}
                                        suffix={upfrontMembershipisPercentage ? '%' : ''}
                                        label="Upfront Membership Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['upfrontMembership']?.amount ? dataFees?.['upfrontMembership']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (upfrontMembershipisPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'upfrontMembership.isPercentage')}
                                            size='medium'
                                            checked={upfrontMembershipisPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        allowNegative={false}
                                        error={Boolean(errors.fractionalized?.message)}
                                        fullWidth
                                        helperText={errors.fractionalized?.message}
                                        onValueChange={(v) => setValue('fractionalized.amount', v.floatValue)}
                                        decimalScale={2}
                                        prefix={fractionalizedisPercentage ? '' : '$'}
                                        suffix={fractionalizedisPercentage ? '%' : ''}
                                        label="Fractionalized Property Fee"
                                        sx={{ mt: 2 }}
                                        value={dataFees?.['fractionalized']?.amount ? dataFees?.['fractionalized']?.amount : 0}
                                        isAllowed={(values) => {
                                            const { value } = values;
                                            if (fractionalizedisPercentage) {
                                                return Number(value) <= 100;
                                            } else {
                                                return true;
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        mt: 3,
                                    }}>
                                        <Typography variant="h6">Fixed</Typography>
                                        <Switch
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => handlePercentageChange(e, 'fractionalized.isPercentage')}
                                            size='medium'
                                            checked={fractionalizedisPercentage}
                                        />
                                        <Typography variant="h6">Percentage</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" spacing={3} pt={4}>
                        <Grid item>
                            <Button type="submit" disabled={formState.isSubmitting} variant="outlined">
                                Save Fees
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    )
}

export default PaymentFees;
