import {Box, Container } from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Column} from '@material-table/core';
import {BrixTable} from '@admin/components/common';
import { IPage, AddingStyleHeader} from '@brix/shared-frontend';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';

const columns: Column<any>[] = [
  {
    title: 'User Name',
    field: 'name',
    customSort: (a, b) => a.name.toLowerCase() - b.name.toLowerCase(),
  },
  {
    title: 'Last Activity',
    field: 'lastActivity',
    type: 'string',
    customSort: (a, b) => a.owner.toLowerCase() - b.owner.toLowerCase(),
  },
  {
    title: 'IP Address',
    field: 'ipAddress',
    type: 'string',
  },
  {
    title: 'Last Log Date',
    field: 'updatedAt',
    type: 'string',
  },
];


const LogsActivitiesList: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    document.title = 'Logs Activities List';
  }, []);


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <BrixTable
            endpoint="/"
            columns={AddingStyleHeader(columns)}
            title="Logs Activities"
            onRowClick={(_, row) => history.push(`/logs-activities/${row.id}`, row)}
            hasToRefetch={refetch}
            setRefetch={setRefetch}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default LogsActivitiesList;
