import {Button, Grid, SvgIcon} from '@mui/material';
import {useParams, useHistory} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {TypeId} from '@brix/shared-frontend';

const Header: React.FunctionComponent = () => {

const { id } = useParams<TypeId>();
const history = useHistory();

  return (
    <Grid container justifyContent="space-between">
      <Button
        variant="outlined"
        size="small"
        sx={{borderRadius: '25px', color: 'black', borderColor: 'rgba(0,0,0,0.1)', my: {xs: 2, sm: 0}}}
        onClick={() => history.push(`/properties/${id}`)}
        startIcon={
          <SvgIcon fontSize="small">
            <ArrowBackIosIcon />
          </SvgIcon>
        }
      >
        {'Go to details'}
      </Button>
    </Grid>
  );
};

export default Header;
