export enum FeesTypes {
    buyBrix = 'buyBrix',
    sellBrix = 'sellBrix',
    resaleBrix = 'resaleBrix',
    transferFee = 'transferFee',
    disassemble = 'disassemble',
    assemble = 'assemble',
    upfrontMembership = 'upfrontMembership',
    fractionalized = 'fractionalized',
}
