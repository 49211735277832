import {Box, Container} from '@mui/material';
import React, {useEffect} from 'react';
import {useParams, useLocation} from 'react-router';
import {UserInfo} from './UserInfo';
import {ProfileInfo} from './ProfileInfo';
import {UserAddress} from './UserAddress';
import {errorHandler, IPage, UserRole} from '@brix/shared-frontend';
import {toast} from 'react-toastify';
import {api} from '@admin/utils';
import {useQuery} from 'react-query';

const loadCountries = async () => {
  const res = await api['/countries'].get('?limit=300&fields=id,name,iso3');
  return res.data;
};

const loadProfile = async (userId: string) => {
  const res = await api['/users'].get(`/${userId}?include=address&include=profile&include=address.country`);
  return res.data;
};

const UserDetailView: React.FunctionComponent<IPage> = () => {
  const {id} = useParams<{id: string}>();
  const {state} = useLocation();

  const {data: user, error: isProfileError} = useQuery(['/users', {id}], () => loadProfile(id), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (isProfileError) {
      toast.error(errorHandler(isProfileError));
    }

  }, [isProfileError]);

  const {data: countries, error: isCountryError} = useQuery(['/countries'], () => loadCountries(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (isCountryError) {
      toast.error('Error loading countries');
    }
  }, [isCountryError]);

  useEffect(() => {
    document.title = 'Users Details';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{mb: 4}}>
          <UserInfo {...state} />
        </Box>
        {user?.roles[0] === UserRole.User || user?.roles[0] === UserRole.Investor ? (
          <>
            <Box sx={{mb: 4}}>
              <ProfileInfo userId={user?.id} />
            </Box>
            <Box>
              <UserAddress country={countries} />
            </Box>
          </>
        ) : null}
      </Container>
    </Box>
  );
};

export default UserDetailView;

