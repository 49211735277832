import {ThemeProvider} from '@mui/material';
import React from 'react';
import {Router} from 'react-router-dom';
import {SettingsConsumer, SettingsProvider} from '@brix/shared-frontend';
import routes, {renderRoutes} from '../routes';
import SplashScreen from '@admin/components/SplashScreen';
import {QueryClient, QueryClientProvider} from 'react-query';
import {createTheme} from '../theme';
import {AuthConsumer, AuthProvider} from '../contexts/AuthContext';
import {createBrowserHistory} from 'history';
import {ToastContainer} from 'react-toastify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent<Record<string, unknown>> = () => {
  const history = createBrowserHistory();

  return (
    <AuthProvider>
      <SettingsProvider>
        <SettingsConsumer>
          {({settings}) => (
            <ThemeProvider
              theme={createTheme({
                mode: settings.theme,
                direction: settings.direction,
                responsiveFontSizes: settings.responsiveFontSizes,
                theme: settings.theme,
              })}
            >
              <Router history={history}>
                <QueryClientProvider client={queryClient}>
                  <AuthConsumer>
                    {(auth) => (!auth.isInitialized ? <SplashScreen/> : renderRoutes(routes))}
                  </AuthConsumer>
                </QueryClientProvider>
                <ToastContainer/>
              </Router>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </AuthProvider>
  );
};

export default App;
