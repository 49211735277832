import React from 'react';

const Page = React.forwardRef<HTMLInputElement, {children: React.ReactNode; title: string}>(
  ({children, title = '', ...rest}, ref) => {
    React.useEffect(() => {
      document.title = 'BRiX | ' + title;
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }, [title]);

    return (
      <div ref={ref} {...rest}>
        {children}
      </div>
    );
  }
);

export default Page;
