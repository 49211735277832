import {Box, Card, Container, Divider, Link, Typography} from '@mui/material';
import {IPage} from '@brix/shared-frontend';
import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Logo from '@admin/components/Logo';

import LoginForm from './LoginForm';

const LoginView: React.FC<IPage> = () => {
  useEffect(() => {
    document.title = 'Login';
  }, []);

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'background.default',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          py: {
            xs: '60px',
            md: '120px',
            lg: '60px',
          },
        }}
      >
        <Card elevation={16} sx={{p: 4}}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Logo width={80} height={80} />
            <Typography variant="h4">Log in</Typography>
            <Typography color="textSecondary" sx={{mt: 2}} variant="body2">
              Sign in to the internal platform
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              mt: 3,
            }}
          >
            <LoginForm />
          </Box>
          <Divider sx={{my: 3}} />
          <Link component={RouterLink} to="/forgot-password" color="textSecondary" sx={{mt: 1}} variant="body2">
            Forgot password?
          </Link>
        </Card>
      </Container>
    </Box>
  );
};

export default LoginView;
