import { Box, Container, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Column } from '@material-table/core';
import { BrixTable } from '@admin/components/common';
import { validator, errorHandler, getAdminRoles, UserRole, IPage, IUserCreate, AddingStyleHeader, UserStatus, firstLetterUppercase } from '@brix/shared-frontend';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { api } from '@admin/utils';
import { useMutation, useQueryClient } from 'react-query';

const columns: Column<any>[] = [
  {
    title: 'First Name',
    field: 'firstName',
    customSort: (a, b) => a.firstName.toLowerCase() - b.firstName.toLowerCase(),
    type: 'string',
    validate: validator(
      Yup.object().shape({
        firstName: Yup.string().min(3).max(32).required('First name is required'),
      })
    ),
  },
  {
    title: 'Last Name',
    field: 'lastName',
    customSort: (a, b) => a.lastName.toLowerCase() - b.lastName.toLowerCase(),
    type: 'string',
    validate: validator(
      Yup.object().shape({
        lastName: Yup.string().min(3).max(32).required('Last name is required'),
      })
    ),
  },
  {
    title: 'Email',
    field: 'email',
    type: 'string',
    customSort: (a, b) => a.email.toLowerCase() - b.email.toLowerCase(),
    editPlaceholder: 'Please enter email',
    validate: validator(
      Yup.object().shape({
        email: Yup.string().email('Please enter valid email.').max(32).required('Email is required'),
      })
    ),
  },
  {
    title: 'Role',
    field: 'roles',
    customSort: (a, b) => a.roles[0].toLowerCase() - b.roles[0].toLowerCase(),
    type: 'string',
    render: (data) => { return data.roles.map((role: UserRole) => {
      const roles = []
      switch (role) {
        case UserRole.Admin:
          roles.push('Admin')
          break;
        case UserRole.Investor:
          roles.push('Investor')
          break;
        case UserRole.SuperAdmin:
          roles.push('Super Admin')
          break;
      }
      return roles
    }
      ).join(', ')},
    editComponent: (props) => {
      return (
        <Select defaultValue={typeof props.rowData.role === 'object' ? props.rowData.roles?.join(', ') : props.rowData.roles} onChange={(e) => props.onChange(e.target.value)}>
          <MenuItem value={UserRole.SuperAdmin}>
            Super Admin
          </MenuItem>
          <MenuItem value={UserRole.Admin}>
            Admin
          </MenuItem>
          <MenuItem value={UserRole.Investor} disabled>
            Investor
          </MenuItem>
        </Select>
      );
    },
  },
  {
    title: 'Status',
    field: 'status',
    customSort: (a, b) => a.status.toLowerCase() - b.status.toLowerCase(),
    type: 'string',
    editComponent: (props) => {
      return (
        <Select defaultValue={props.rowData.status} onChange={(e) => props.onChange(e.target.value)}>
          <MenuItem value={UserStatus.New}>
            New
          </MenuItem>
          <MenuItem value={UserStatus.InReview}>
            In Review
          </MenuItem>
          <MenuItem value={UserStatus.Approved}>
            Approved
          </MenuItem>
          <MenuItem value={UserStatus.Rejected}>
            Rejected
          </MenuItem>
        </Select>
      );
    },
  },
];

const createUser = async (data: IUserCreate) => {
  data.firstName = firstLetterUppercase(data.firstName)
  data.lastName = firstLetterUppercase(data.lastName)
  const res = await api['/users'].post('', data);
  return res.data;
};

const updateUser = async (id: string, data: IUserCreate) => {
  data.firstName = firstLetterUppercase(data.firstName)
  data.lastName = firstLetterUppercase(data.lastName)
  const res = await api['/users'].patch(`/${id}`, data);
  return res.data;
};

const UserListView: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    document.title = 'Users';
  }, []);

  const sendRecoverPassword = async (email: string) => {
    try {
      await api['/auth'].post('/recover-password', { email });
    } catch (e: any) {
      toast.error(errorHandler(e.data));
    }
  };

  const mutationCreate = useMutation((data: IUserCreate) => createUser(data), {
    onSuccess: async (res: any) => {
      queryClient.resetQueries('users');
      await sendRecoverPassword(res.email);
      toast.success(`${res.roles[0]} has been created successfully`);
      setRefetch(true);
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
      setRefetch(true);
    },
  });

  const mutationUpdate = useMutation(({ id, data }: { id: string; data: IUserCreate }) => updateUser(id, data), {
    onSuccess: (res: any) => {
      queryClient.resetQueries('users');
      toast.success(`User has been updated successfully`);
      setRefetch(true);
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
      Promise.reject(err);
      setRefetch(true);
    },
  });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <BrixTable
            endpoint="/users"
            columns={AddingStyleHeader(columns)}
            title="User List"
            isRowDelete={true}
            onRowClick={(_, row) => history.push(`/users/${row.id}`, row)}
            onRowAdd={(newData, fetchCallback) => {
              return new Promise((resolve, reject) => {
                newData.password = Math.random().toString(36).slice(-12);
                newData.roles = [newData.roles];
                mutationCreate.mutate(newData);
                fetchCallback && fetchCallback();
                resolve(null);
              });
            }}
            onRowUpdate={(newData, oldData, fetchCallback) => {
              return new Promise((resolve, reject) => {
                newData.roles = [newData.roles];
                mutationUpdate.mutate({ id: oldData.id, data: newData });
                fetchCallback && fetchCallback();
                resolve(null);
              });
            }}
            hasToRefetch={refetch}
            setRefetch={setRefetch}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default UserListView;
