export const errorHandler = (error: any) => {
  if (!error || !error.message) return null;

  switch (true) {
    case error.statusCode === 404:
      return 'Resource not found';
    case error.statusCode === 403:
      return 'Forbidden to access this resource';
    case error.statusCode >= 500 && error.statusCode < 600:
      return 'Something went wrong';
    case typeof error.message === 'string':
      return error.message;
    case Boolean(error?.message?.length) === true:
      return error.message[0];
  }
};
