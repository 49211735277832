import { Column } from '@material-table/core';

export const AddingStyleHeader = (columns: Column<any>[]) => {
    return columns.map((column: Column<any>) => {
     return (
       {
         ...column,
         headerStyle: {
           paddingBottom: '0.45%',
         },
       }
     )
   }
 )
}
