import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { IPage } from '@brix/shared-frontend';
import PaymentFees from './components/PaymentFees';

const ManagementFees: React.FunctionComponent<IPage> = () => {
    useEffect(() => {
        document.title = 'Management Fees List';
    }, []);

    return (

        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 3,
                backgroundColor: 'background.default',
                height: '100%',
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="xl">
                <Box  sx={{ mb: 4 }}>
                    <PaymentFees />
                </Box>
            </Container>
        </Box>


    )
}

export default ManagementFees;

