import {Button, Divider, Grid, Typography} from '@mui/material';
import {IPropertyAddress, IPropertySpecs, ICountrySelect} from '@brix/shared-frontend';
import api from '@admin/utils/api';
import React, { useEffect } from 'react';
import Specs from './Specs';
import Hightlights from './Hightlights';
import About from './About';
import Maps from './Maps';
import AddressMap from './IframeMap';
import Financials from './Financials';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useQuery} from 'react-query';

const Details: React.FC<{
  propertyData: IPropertySpecs;
  addressItem?: IPropertyAddress;
  featuredImageUrl: string;
  openBrixModal: () => void;
}> = (propertyData) => {

  const loadCountries = async (): Promise<ICountrySelect[]> => {
    const res = await api['/countries'].get('?limit=300&fields=id,name,iso3&sort=name,ASC');
    return res.data.data;
  };

  const {data: countries} = useQuery('countries', loadCountries);

  return (
    <Grid container spacing={4} sx={{my: 0}}>
      <Grid item xs={12} sm={8} md={8}>
        <Hightlights {...propertyData} />
        <About {...propertyData} />
        <Divider sx={{my: 4}} />
        <Specs {...propertyData} />
        <Divider sx={{my: 4}} />
        <AddressMap {...propertyData} />
        {propertyData?.propertyData?.city && propertyData?.propertyData?.city !== 'Unspecified' ? (
          <Button sx={{my: 1}} endIcon={<ArrowForwardIosIcon sx={{color: 'primary'}} fontSize="small" />}>
            <Typography sx={{fontWeight: 600}} color="primary" variant="body2">
              {'See more listings in ' + propertyData?.propertyData?.city}
            </Typography>
          </Button>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        <Financials {...propertyData} />
      </Grid>
    </Grid>
  );
};

export default Details;
