export enum UserRole {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
  Investor = 'investor',
  User = 'user',
}

export enum UserRoleAdmin {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  Investor = 'Investor',
  User = 'User',
}

export enum UserStatus {
  New = 'New',
  InReview = 'In Review',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
