import {Box, ListItem, ListItemText, TextField, Typography} from '@mui/material';
import {Controller} from 'react-hook-form';
import {FormSelect} from '.';
import {IPropertyListItem} from './interfaces';

export const PropertyListItem = (props: IPropertyListItem) => {
  const {
    align,
    children,
    disableGutters,
    value,
    name,
    label,
    isEdit,
    itemType,
    error,
    control,
    inputProps,
    selectProps,
    ...other
  } = props;

  return (
    <ListItem sx={{px: disableGutters ? 0 : 3, py: 1.5, alignItems: 'center', width: '100%'}} {...other}>
      <ListItemText
        disableTypography
        primary={
          <Typography sx={{minWidth: align === 'vertical' ? 'inherit' : 180}} color="textSecondary" variant="subtitle2">
            {label}
          </Typography>
        }
        secondary={
          isEdit ? (
            <Controller
              name={name ? name : ''}
              control={control}
              defaultValue={value}
              render={({field: {onChange, value}}) =>
                itemType === 'input' && inputProps ? (
                  <TextField
                    size="small"
                    placeholder={inputProps.placeholder}
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    type={inputProps.type}
                    helperText={
                      error ? (
                        <Typography color="red" variant="caption">
                          {error}
                        </Typography>
                      ) : null
                    }
                  />
                ) : (
                  <FormSelect
                    onChange={onChange}
                    name={name ? name : ''}
                    options={selectProps?.options}
                    value={value}
                  />
                )
              }
            />
          ) : (
            <Box sx={{flex: 1, mt: align === 'vertical' ? 0.5 : 0}}>
              {children || (
                <Typography color="textSecondary" variant="body2">
                  {value}
                </Typography>
              )}
            </Box>
          )
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
        }}
      />
    </ListItem>
  );
};
