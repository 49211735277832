import {Box, Container } from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Column} from '@material-table/core';
import {BrixTable} from '@admin/components/common';
import {validator, errorHandler, IPage, AddingStyleHeader} from '@brix/shared-frontend';
import {ICompanyCreate} from '../company/interfaces';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { api } from '@admin/utils';
import { useMutation, useQueryClient } from 'react-query';
import { IAddress } from '@admin/views/dashboard/company/interfaces';

const columns: Column<any>[] = [
  {
    title: 'Company Name',
    field: 'name',
    customSort: (a, b) => a.name.toLowerCase() - b.name.toLowerCase(),
    type: 'string',
    validate: validator(
      Yup.object().shape({
        name: Yup.string().min(3).max(32).required('Company Name'),
      })
    ),
  },
  {
    title: 'Company Owner',
    field: 'owner',
    type: 'string',
    customSort: (a, b) => a.owner.toLowerCase() - b.owner.toLowerCase(),
    validate: validator(
      Yup.object().shape({
        owner: Yup.string().min(3).max(32).required('Company Owner'),
      })
    ),
  },
  {
    title: 'Phone Number',
    field: 'phone',
    type: 'string',
    validate: validator(
      Yup.object().shape({
        phone: Yup.string().min(10).max(32).required('Company Phone Number'),
      })
    ),
  },
  {
    title: 'Company Email',
    field: 'email',
    type: 'string',
    validate: validator(
      Yup.object().shape({
        email: Yup.string().email().max(32).required('Company Name'),
      })
    ),
  },
];

const createCompany = async (data: ICompanyCreate) => {
  const res = await api['/companies'].post('', data);
  return res.data;
};

const createAddress = async (companyId: string, address: IAddress) => {
  const { data } = await api['companies'].post(`/${companyId}/address`, address);
  return data;
};

const updateCompany = async (id: string, data: ICompanyCreate) => {
  const res = await api['/companies'].patch(`/${id}`, data);
  return res.data;
};

const CompanyList: React.FunctionComponent<IPage> = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    document.title = 'Company List';
  }, []);


  const mutationCreate = useMutation((data: ICompanyCreate) => createCompany(data), {
    onSuccess: async (res: any) => {
      await createAddress(res.id, {
        street: '',
        street2: '',
        city: '',
        zipCode: '',
        stateCode: 'FL',
        countryId: 'USA',
      })
      queryClient.resetQueries('users');
      toast.success(`Company has been created successfully`);
      setRefetch(true);
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
      setRefetch(true);
    },
  });

  const mutationUpdate = useMutation(({ id, data }: { id: string; data: ICompanyCreate }) => updateCompany(id, data), {
    onSuccess: (res: any) => {
      queryClient.resetQueries('users');
      toast.success(`Company has been updated successfully`);
      setRefetch(true);
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
      Promise.reject(err);
      setRefetch(true);
    },
  });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <BrixTable
            endpoint="/companies"
            columns={AddingStyleHeader(columns)}
            title="Company List"
            isRowDelete={true}
            onRowClick={(_, row) => history.push(`/companies/${row.id}`, row)}
            onRowAdd={(newData) => {
              return new Promise((resolve, reject) => {
                mutationCreate.mutate(newData);
                resolve(null);
              });
            }}
            onRowUpdate={(newData, oldData) => {
              return new Promise((resolve, reject) => {
                mutationUpdate.mutate({id: oldData.id, data: newData});
                resolve(null);
              });
            }}
            hasToRefetch={refetch}
            setRefetch={setRefetch}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default CompanyList;
