import {ConstructionTypes, PropertyStatusTypes, PropertyTypes} from '../types';
import {ICountrySelect, IStateSelect} from '../interfaces';
import {titleCase} from './texts';

export const getPropertyStatusList = () => {
  const status: Array<{ label: string; value: number }> = [];
  Object.values(PropertyStatusTypes).map((st: any, idx) => {
    status.push({label: st, value: idx});
  });
  return status;
};

export const getPropertyStatus = (statusId: number) => {
  return Object.values(PropertyStatusTypes)[statusId];
};

export const getPropertyStatusId = (status: PropertyStatusTypes) => {
  return Object.values(PropertyStatusTypes).indexOf(status);
};

/**
 * Find the number from and string
 */
export const findNumberFromString = (str: string): number => {
  const number = str.match(/\d+/g);
  return number ? parseInt(number[0]) : 0;
};

export const setMultipleValues = (setValue: any, values: any) => {
  Object.keys(values).forEach((key) => {
    setValue(key, values[key]);
  });
};

/**
 * get list of construction types
 */
export const getConstructionTypes = () => {
  const types: Array<{ label: string; value: number }> = [];
  Object.values(ConstructionTypes).map((t: any, idx) => {
    types.push({label: t, value: idx});
  });
  return types;
};

/**
 * Calculate the management fee
 * @param {number} rentalNetAmount
 * Management fee is 10% of rental net amount
 */
export const calculateManagementFee = (rentalNetAmount: number): number => {
  return rentalNetAmount * 0.1;
};

/**
 * Calculate the reserve amount
 * @param {number} closingValue
 * Reserve amount calculated based on gross value
 * closingValue < 200000 => reserveAmount = grossValue * 0.035 (3.5%)
 * closingValue > 200000 => reserveAmount = grossValue * 0.03 (3%)
 * closingValue > 300000 => reserveAmount = grossValue * 0.025 (2.5%)
 */
export const calculateReserveAmount = (closingValue: number): number => {
  if (closingValue < 200000) {
    return closingValue * 0.035;
  } else if (closingValue > 200000 && closingValue < 300000) {
    return closingValue * 0.03;
  } else {
    return closingValue * 0.025;
  }
};

/**
 * Get country id from country iso3 code
 * @param {string} iso3
 * @param {Array<any>} countries
 */
export const getCountryId = (iso3: string, countries: Array<ICountrySelect> | undefined) => {
  const country = countries?.find((c: ICountrySelect) => c.iso3 === iso3);
  return country ? country.id : '';
};

/**
 * Get country iso3 code from country id
 * @param {number} id
 * @param {Array<any>} countries
 * @returns {string}
 */
export const getCountryIso3 = (id: string, countries: Array<ICountrySelect> | undefined) => {
  const country = countries?.find((c: ICountrySelect) => c.id === id);
  return country ? country.iso3 : '';
};

/**
 * Get country name from country id
 * @param {number} id
 * @param {Array<any>} countries
 * @returns {string}
 */
export const getCountryName = (id: string, countries: Array<ICountrySelect> | undefined) => {
  const country = countries?.find((c: ICountrySelect) => c.id === id);
  return country ? country.name : '';
};

export const getStateName = (stateCode: string, states: Array<IStateSelect> | undefined) => {
  const state = states?.find((s: IStateSelect) => s.stateCode === stateCode);
  return state ? state.name : '';
};

/**
 * Returns TitleCase with property type, depending on given original type
 * @param type {number | string} with the original type
 * @returns {PropertyTypes}
 */
export const propertyTypeOf = (type: number | string) : string=> {
  return titleCase(PropertyTypes[Number(type)]) ?? '';
}
