// noinspection RequiredAttributes

import React, {forwardRef} from 'react';
import {Control, Controller, FieldError} from 'react-hook-form';
import {TextField, TextFieldProps} from '@mui/material';
import DatePicker from '@mui/lab/MobileDatePicker';

interface DateArgs<T> {
  name: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  control: Control<any>;
  error?: { message: string } | FieldError;
}

const DateField = forwardRef(<T, >({control, name, error, label, minDate, maxDate}: DateArgs<T>) => {
  return (
    <Controller name={name}
                control={control}
                render={({field: {onChange, value}}) => {
                  return (
                    <DatePicker value={value} label={label} minDate={minDate}
                                maxDate={maxDate} onChange={onChange}
                                renderInput={(props: TextFieldProps) => (
                                  <TextField {...props} error={Boolean(error)}
                                             helperText={error?.message}
                                             fullWidth sx={{mt: 2}}/>
                                )}/>
                  );
                }}/>
  );
});

export default DateField;
