export const formatBrixies = (brixies: number) => {
  const result: { stax: number; brix: number; brixie: number } = {stax: 0, brix: 0, brixie: 0};
  if (brixies > 99) {
    result.stax = Math.trunc(brixies / 100);
    result.brix = Math.trunc(brixies / 10) - Math.trunc(brixies / 100) * 10;
    result.brixie = brixies - Math.trunc(brixies / 10) * 10;
  } else if (brixies > 9) {
    result.stax = 0;
    result.brix = Math.trunc(brixies / 10);
    result.brixie = brixies - Math.trunc(brixies / 10) * 10;
  } else {
    result.stax = 0;
    result.brix = 0;
    result.brixie = brixies;
  }
  return result;
};

export const brixFeeLabel = (fee?: Partial<{ isPercentage: boolean, amount: number }>) => {
  return fee?.isPercentage ? `${fee.amount}%` : `$${fee?.amount}`;
};

export const calcBrixFeeAmount = (amount: number, fee?: Partial<{ isPercentage: boolean, amount: number }>) => {
  return fee?.isPercentage ? (amount * fee.amount! / 100) : ((amount > 0) ? Number(fee?.amount ?? 0) : 0);
};
