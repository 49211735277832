import {yupResolver} from '@hookform/resolvers/yup';
import {Alert, Box, Button, FormHelperText, TextField} from '@mui/material';
import api from '@admin/utils/api';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useMutation} from 'react-query';
import * as Yup from 'yup';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
});

const ForgotPasswordForm = ({...rest}) => {
  const [success, setSuccess] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const {register, handleSubmit, formState} = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const {errors} = formState;

  const mutation = useMutation((email) => api['auth'].post('/recover-password', {email}), {
    onSuccess: ({status}) => {
      setSuccess(status === 202);
    },
    onError: () => {
      setInvalidData(true);
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    setSuccess(false);
    setInvalidData(false);
    mutation.mutate(data.email);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
      {!success && (
        <>
          <TextField
            {...register('email')}
            fullWidth
            autoFocus
            helperText={errors?.['email']?.message}
            error={Boolean(errors?.['email'])}
            label="Email Address"
            margin="normal"
            name="email"
            type="email"
            placeholder="Email"
            variant="outlined"
            disabled={success}
          />
          {invalidData && (
            <Box mt={3}>
              <FormHelperText error>Invalid email. Please, review your data.</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="primary"
              disabled={formState.isSubmitting || success}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Recover Password
            </Button>
          </Box>
        </>
      )}
      <Box mt={2}>
        <Alert severity={!success ? 'info' : 'success'}>
          <div>
            {!success &&
              'Please, enter your email and we will send an email with instructions ' + 'to reset your password.'}
            {success && 'Please, review your email. An email has been sent to your inbox'}
          </div>
        </Alert>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
