const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const digitFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatterNoSymbol = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 5,
});

export const formatPrice = (price?: number) => {
  return formatter.format(isNaN(price ?? 0) ? 0 : (price ?? 0));
};

export const formatWithDigits = (price?: number) => {
  return digitFormatter.format(isNaN(price ?? 0) ? 0 : (price ?? 0));
};

export const formatPriceWithoutSymbol = (price?: number) => {
  return formatterNoSymbol.format(isNaN(price ?? 0) ? 0 : (price ?? 0));
};

export const formatBrixUnit = (brixies: number, brix: number, stax: number) => {
  return `${(stax > 0) ? `${stax} Stax, ` : ''}${(brix > 0) ? `${brix} BRiX, ` : ''}${brixies} BRiXies`
};
