import {Button, Card, CardContent, CardMedia, CircularProgress, Dialog, Grid, IconButton} from '@mui/material';
import React, {useState, useRef, useEffect} from 'react';
import {toast} from 'react-toastify';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import {Image} from 'mui-image';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import api from '@admin/utils/api';
import {useQuery} from 'react-query';

interface IPropertyDetailsMedia {
  createAr: string,
  id: string,
  key: string,
  mediaType: number,
  metadata: {
    mime: string,
    size: number,
  },
  propertyId: string,
  status: number,
  updatedAt: string,
}

interface IMediaCarrousel {
  original: string,
  thumbnail: string,
}

const loadPropertyImages = async (propertyId: string) => {
  const {data} = await api['media'].get(`?filter=propertyId||$eq||${propertyId}`);
  return data;
};

const Media: React.FC<{
  featuredImageUrl: string;
  videoUrl?: string;
  images?: string[];
  propertyId: string | undefined;
}> = (props) => {
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [imageList, setImageList] = useState<IMediaCarrousel[]>([]);
  const imageGallery = useRef<ImageGallery>(null);

  const {data: propertyImages, isLoading: propertyImagesLoading} = useQuery<IPropertyDetailsMedia[], IPropertyDetailsMedia[]>(
    ['media', 'preview', {propertyId: props.propertyId}],
    () => loadPropertyImages(`${props.propertyId}`)
  );
  useEffect(() => {
    if (propertyImages) {
      
      const images: IMediaCarrousel[] = propertyImages.map( (image: IPropertyDetailsMedia) => ({
        original: image.key,
        thumbnail: image.key
        }));
        const index = images.findIndex( image => image.original === props.featuredImageUrl)
        if (index !== -1) {
          const poster = images.splice(index,1)
          images.push(poster[0])
          setImageList(images);
        }
      setImageList(images);
    }
  }, [propertyImages, props]);

  const handleClose = () => {
    setIsImageGalleryOpen(false);
  };

  const handleVideo = () => {
    toast.error('Video not available', {
      position: 'top-left',
      pauseOnHover: false,
      closeOnClick: true,
      autoClose: 3000,
    });
  };
  const handleViewAllPhotos = () => {
    setIsImageGalleryOpen(true);
  };

  useEffect(() => {
    if (isImageGalleryOpen) imageGallery?.current?.fullScreen();
  }, [isImageGalleryOpen]);

  return (
    <>
      <Grid container spacing={4} sx={{my: 0, height: '100%'}}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{transition: 'all 0.3s ease-in-out', '&:hover': {cursor: 'pointer', opacity: 0.8}}}
          onClick={handleViewAllPhotos}
        >
          <Image
            src={props.featuredImageUrl ? props.featuredImageUrl : '../assets/svg/logo-demo.svg'}
            duration={100}
            easing="ease-in-out"
            showLoading={<CircularProgress />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={12}>
              <Card sx={{mt: 0}}>
                <CardContent
                  component="div"
                  onClick={handleViewAllPhotos}
                  sx={{
                    backgroundImage: `url("${
                      imageList?.length > 0
                        ? imageList[0]?.original
                        : '../assets/svg/logo-demo.svg'
                    }")`,
                    backgroundSize: 'cover',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    height: {xs: '100px', sm: '250px'},
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {cursor: 'pointer', opacity: 0.8},
                  }}
                >
                  <Button
                    startIcon={<ImageOutlinedIcon color="primary" />}
                    variant="contained"
                    sx={{
                      ml: 'auto',
                      mr: 'auto',
                      color: 'black',
                      backgroundColor: '#f9f9f9',
                      '&:hover': {backgroundColor: 'rgba(1, 1, 1, 0.38)', color: 'white'},
                      width: '100%',
                    }}
                  >
                    View all photos
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog fullScreen open={isImageGalleryOpen} onClose={handleClose}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{position: 'absolute', right: 0, zIndex: '9', m: 1}}
        >
          <CloseIcon />
        </IconButton>
        <ImageGallery infinite={false} lazyLoad ref={imageGallery} items={imageList} />
      </Dialog>
    </>
  );
};

export default Media;
