import {Box, Typography, Button} from '@mui/material';
import {ICardHeader} from './interfaces';

export const CardHeader = ({title, isEditing, showButton = true, disabled, onClick}: ICardHeader) => {
  return (
    <Box
      sx={{
        py: 3,
        px: 3,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box sx={{}}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {showButton && (
        <Button
          disabled={disabled}
          type={!isEditing ? 'submit' : 'button'}
          color="primary"
          variant={isEditing ? 'contained' : 'outlined'}
          onClick={onClick}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      )}
    </Box>
  );
};
