import {Box, Card, CardContent, Container, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {IFinancial, IPage, TypeId} from '@brix/shared-frontend';
import {PropertyAddress, PropertyBasic, PropertyRental} from './components';
import DragnDropMedia from './components/DragnDropMedia';
import Tabs from './components/Tabs';
import Publishing from './components/Publishing';
import Media from './components/Media';
import {PropertyDescriptions} from '@admin/views/dashboard/properties/[propertyId]/components/PropertyDescriptions';
import {Financial} from '@admin/views/dashboard/properties/[propertyId]/Financial/Financial';
import {api} from '@admin/utils';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router';
import FinancialChart from './Financial/FinancialChart';
import {toast} from 'react-toastify';

const sections = [
  {
    title: 'Details',
    value: 1,
  },
  {
    title: 'Financials',
    value: 2,
  },
  {
    title: 'Media',
    value: 3,
  },
  {
    title: 'Rentals',
    value: 4,
  },
  {
    title: 'Publishing',
    value: 5,
  },
];

const PropertiesDetailsView: React.FunctionComponent<IPage> = () => {
  useEffect(() => {
    document.title = 'Properties';
  }, []);
  const {id} = useParams<TypeId>();
  const [financial, setFinancial] = useState<IFinancial>();
  const [tabSelected, setTabSelected] = useState(1);
  const [propertyName, setPropertyName] = useState('');

  const emitChange = (value: number) => {
    setTabSelected(value);
  };

  const handleUpdateFinancialInfo = () => {
    id && api['/properties'].get(`/${id}/financial/info`).then((info: AxiosResponse) => {
      setFinancial(info.data);
    }, () => {
      toast.error('There was an error trying to load the financial issue for this property.')
    });
  }

  useEffect(handleUpdateFinancialInfo, [id]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 3,
        backgroundColor: 'background.default',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Tabs sections={sections} emitChange={emitChange} tabSelected={tabSelected} propertyName={propertyName}/>
        <Grid container spacing={3} sx={{p: 1}}>
          {tabSelected === 1 ? (
            <Grid container spacing={3} sx={{p: 1}}>
              <Grid item xs={12} sm={6}>
                <PropertyBasic tabSelected={tabSelected} setPropertyName={setPropertyName}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PropertyAddress/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FinancialChart financial={financial} setIndex={index => setTabSelected(index)}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PropertyDescriptions/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PropertyRental onChanged={handleUpdateFinancialInfo}/>
              </Grid>
            </Grid>
          ) : null}
          {tabSelected === 2 ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Box>
                    <Typography variant="h5">Financial</Typography>
                  </Box>
                  <Grid container spacing={2} sx={{p: 1}}>
                    <Financial financial={financial} setFinancial={setFinancial}/>
                    <Grid item xs={12} md={6}>
                      <FinancialChart financial={financial}/>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          {tabSelected === 3 ? (
            <Grid item xs={12}>
              <DragnDropMedia/>
            </Grid>
          ) : null}
          {tabSelected === 4 ? (
            <Grid item xs={12}>
              <PropertyRental onChanged={handleUpdateFinancialInfo}/>
            </Grid>
          ) : null}
          {tabSelected === 5 ? (
            <Grid container spacing={3}>
              <Publishing/>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default PropertiesDetailsView;

