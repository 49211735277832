import {Card, CardContent} from '@mui/material';
import {useParams} from 'react-router';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import api from '@admin/utils/api';
import {BrixTable} from '@admin/components/common';
import {Column} from '@material-table/core';
import {AddingStyleHeader, errorHandler, formatWithDigits, IRental, TypeId, validator} from '@brix/shared-frontend';
import {useMutation, useQueryClient} from 'react-query';
import {useState} from 'react';
import {AxiosResponse} from 'axios';

const columns: Column<any>[] = [
  {
    title: 'Full Name',
    field: 'fullname',
    type: 'string',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        fullname: Yup.string().min(3, 'Full Name must be greater than or equal to 5 charts').max(32).required('Full Name is required'),
      })
    ),
  },
  {
    title: 'Phone',
    field: 'phone',
    type: 'string',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        phone: Yup.string().min(10, 'Phone must be at least 10 characters').max(16).required('Phone is required'),
      })
    ),
  },
  {
    title: 'Email',
    field: 'email',
    type: 'string',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        email: Yup.string().email(),
      })
    ),
  },
  {
    title: 'Lease Deposit Amount',
    field: 'leaseDepositAmount',
    type: 'string',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        leaseDepositAmount: Yup.number().min(0, 'Lease Deposit Amount must be greater than or equal to 0').required('Lease Deposit Amount is required').typeError('Lease Deposit Amount must be a number'),
      })
    ),
    render: (rowData) => formatWithDigits(rowData.leaseDepositAmount),
  },
  {
    title: 'Rental Amount',
    field: 'rentalAmount',
    type: 'string',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        rentalAmount: Yup.number().min(0, 'Rental Amount must be greater than or equal to 0').required('Rental Amount is required').typeError('Rental Amount must be a number'),
      })
    ),
    render: (rowData) => formatWithDigits(rowData.rentalAmount),
  },
  {
    title: 'Lease Start',
    field: 'rentalStartDate',
    type: 'date',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        rentalStartDate: Yup.date().required('Start date is required'),
      })
    ),
  },
  {
    title: 'Lease End',
    field: 'rentalEndDate',
    type: 'date',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        rentalEndDate: Yup.date().required('End date is required'),
      })
    ),
  },
  {
    title: 'Is Active',
    field: 'isActive',
    type: 'boolean',
    filtering: false,
    validate: validator(
      Yup.object().shape({
        isActive: Yup.boolean().typeError('Is Active should be boolean value'),
      })
    ),
    render: (rowData) => rowData.isActive ? 'Active' : 'Inactive',
  },
];

const updateRental = async (propertyId: string, rentalId: string, rental: IRental) => {
  const {data} = await api['properties'].patch(`/${propertyId}/rentals/${rentalId}`, rental);
  return data;
};

export type UpdateRentalParams = { propertyId: string; rentalId: string; data: IRental };

export interface RentalParams {
  onChanged: VoidFunction;
}

export const PropertyRental = ({onChanged}: RentalParams) => {
  const {id} = useParams<TypeId>();
  const queryClient = useQueryClient();
  const [refetch, setRefetch] = useState(false)

  const createRental = async (propertyId: string, rental: IRental) => {
    const {data} = await api['properties'].post(`/${propertyId}/rentals`, rental);
    return data;
  };

  const mutationCreate = useMutation((data: IRental) => createRental(id, data), {
    onSuccess: async () => {
      await queryClient.resetQueries('rentals');
      toast.success('Renter has been created successfully');
      setRefetch(true);
      onChanged();
    },
    onError: (err: any) => {
      toast.error(errorHandler(err.data));
      setRefetch(true);
      onChanged();
    },
  });

  const mutationUpdate = useMutation(({propertyId, rentalId, data}: UpdateRentalParams) => {
      return updateRental(propertyId, rentalId, data)
    }, {
      onSuccess: async () => {
        await queryClient.resetQueries('rentals');
        toast.success('Renter has been updated successfully');
        setRefetch(true);
        onChanged();
      },
      onError: (err: AxiosResponse) => {
        toast.error(errorHandler(err.data));
        setRefetch(true);
        onChanged();
      },
    }
  );

  const handleSaveRental = (rental: IRental, currentRental?: IRental) => {
    return new Promise((resolve, reject) => {
      if (rental.rentalStartDate > rental.rentalEndDate) {
        toast.error('Start date must be less than end date');
        reject();
        return;
      }
      rental.rentalStartDate = new Date(rental.rentalStartDate).toISOString();
      rental.rentalEndDate = new Date(rental.rentalEndDate).toISOString();
      rental.rentalAmount = Number(rental.rentalAmount);
      rental.leaseDepositAmount = Number(rental.leaseDepositAmount);
      if (currentRental) {
        mutationUpdate.mutate({propertyId: id, rentalId: currentRental.id, data: rental});
      } else {
        mutationCreate.mutate(rental);
      }
      setRefetch(true);
      resolve(null);
    });
  }


  return (
    <Card>
      <CardContent>
        <BrixTable title="Rentals"
                   endpoint={`/properties/${id}/rentals`}
                   columns={AddingStyleHeader(columns)}
                   isRowDelete={true}
                   onRowAdd={(rental: IRental) => handleSaveRental(rental)}
                   onRowUpdate={(rental: IRental, oldData: IRental) => handleSaveRental(rental, oldData)}
                   hasToRefetch={refetch}
                   setRefetch={setRefetch}/>
      </CardContent>
    </Card>
  );
};
