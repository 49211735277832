import React, { useState, useEffect } from 'react'
import { Button, Card, CardMedia, Container, Grid } from '@mui/material';
import { errorHandler } from '@brix/shared-frontend';
import api from '@admin/utils/api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface MediaCRUDProps {
    hasToRefetch?: boolean;
    setHasToRefetch?: React.Dispatch<React.SetStateAction<boolean>>
}

const deleteMedia = async (id: string) => {
    const { data } = await api['media'].delete(`/${id}`);
    return data;
};

const createPoster = async (id: string) => {
    const {data} = await api['media'].post(`/${id}/poster`);
    return data;
  };

const MediaCRUD: React.FC<MediaCRUDProps> = ({ hasToRefetch, setHasToRefetch }) => {

    const [media, setMedia] = useState([])
    const [posterId, setPosterId] = useState('')
    const {id} = useParams<any>();
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const [modalPosterOpen, setModalPosterOpen] = useState(false)
    const [idToDelete, setIdToDelete] = useState('')
    const [idToSetPoster, setIdToSetPoster] = useState('')

    const handleCloseDeleteModal = () => {
        setModalDeleteOpen(false);
    };

    const handleClosePosterModal = () => {
        setModalPosterOpen(false);
    };

    const OpenModalDelete = (id: string) => {
        setIdToDelete(id)
        setModalDeleteOpen(true);
    }

    const OpenModalPoster = (id: string) => {
        setIdToSetPoster(id)
        setModalPosterOpen(true);
    }

    const {
        isLoading,
        isError,
        data: fetchedProperties,
        refetch: refetchData,
    } = useQuery(['media', id], () =>
        api['media'].get(`/`, {
            filter: `propertyId||$eq||${id}`,
        })
    );

      const {
        data: posterIdData,
        refetch: refetchPosterIdData,
      } = useQuery(['property', id], () =>
        api['properties'].get(`/${id}`, {include: ['poster']})
      );

      useEffect(() => {
        if (posterIdData?.data?.__poster__?.id) {
            setPosterId(posterIdData.data.__poster__.id)
        }
        } , [posterIdData])

    useEffect(() => {
        if (fetchedProperties) {
            setMedia(fetchedProperties.data)
        }
    }, [fetchedProperties])

    useEffect(() => {

        if (hasToRefetch && setHasToRefetch) {
            refetchData()
            refetchPosterIdData()
            setHasToRefetch(false)
        }

    }, [hasToRefetch])

    const mutationUpdate = useMutation((id: string) => deleteMedia(id), {
        onSuccess: (res: any) => {
            toast.success('Image was deleted successfully');
            refetchData()
            refetchPosterIdData()
        },
        onError: (err: any) => {
            toast.error(errorHandler(err.data));
        },
    });

    const onDeleteMedia = () => {
        mutationUpdate.mutate(idToDelete)
        setModalDeleteOpen(false)
    }

    const mutationCreate = useMutation((id: string) => createPoster(id), {
        onSuccess: (res: any) => {
            toast.success('Image was set as poster successfully');
            refetchData()
            refetchPosterIdData()
        },
        onError: (err: any) => {
            toast.error(errorHandler(err.data));
        },
    });

    const onCreatePoster = () => {
        mutationCreate.mutate(idToSetPoster)
        setModalPosterOpen(false)
    }

    return (
        <Container>
            <Grid container spacing={3}>
                {media.map((item: any) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={item.id}>
                            <Card sx={{
                                border: '3px inset',
                                borderColor: item.id === posterId ? '#CC4645' : 'transparent',
                            }}>
                                <CardMedia component="img" height="250" image={item.key} />
                                <Container sx={{ mb: 2 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                sx={{ mt: 1 }}
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                onClick={() => OpenModalPoster(item.id)}
                                                disabled={item.id === posterId}
                                            >
                                                Set as poster
                                            </Button>
                                        </Grid>{' '}
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => OpenModalDelete(item.id)}
                                                fullWidth
                                                sx={{ mt: 1 }}
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                disabled={item.id === posterId}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Card>
                        </Grid>
                    )
                }
                )}
            </Grid>
            <Dialog
                open={modalPosterOpen}
                onClose={handleClosePosterModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Set as poster
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to put this image as poster of the property?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePosterModal}>Disagree</Button>
                    <Button onClick={onCreatePoster} > Agree </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={modalDeleteOpen}
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete photo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this photo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal}>Disagree</Button>
                    <Button onClick={onDeleteMedia} > Agree </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
export default MediaCRUD;
