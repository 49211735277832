import {apiOf} from '@admin/utils/api';
import {AxiosInstance} from 'axios';

const useApi = () => {

  const properties = (): AxiosInstance => apiOf('properties');
  const supportedLocales = (): AxiosInstance => apiOf('supported-locales');

  return {
    properties,
    supportedLocales,
  }
};

export default useApi
