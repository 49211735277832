import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { baseThemeOptions } from './base';
import { darkThemeOptions } from './darkThemeOptions';
import { lightThemeOptions } from './lightThemeOptions';

export const createTheme = (config) => {
  let theme = createMuiTheme(baseThemeOptions,
    config.mode === 'light' ? lightThemeOptions : darkThemeOptions,
    {
      direction: config.direction
    });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
