import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, FormHelperText, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useAuth from '@admin/hooks/useAuth';
import { useMutation } from 'react-query';

interface IData {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password requires to be at least 8 characters long')
    .required('Password is required'),
});

const LoginForm = ({ ...rest }) => {
  const { login } = useAuth();
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [invalidLoginText, setInvalidLoginText] = useState(
    'Invalid email and/or password'
  );
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const { errors } = formState;

  const mutation = useMutation((data: IData) => login(data.email, data.password), {
    onError: (err: {message: string}) => {
      if (err) {
        setInvalidCredentials(true);
        setInvalidLoginText(err.message);
      }
    },
  });

  const onSubmit = async (data: unknown) => {
    setInvalidCredentials(false);
    mutation.mutate(data as IData);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
      <TextField
        {...register('email')}
        error={Boolean(errors?.['email'])}
        fullWidth
        autoFocus
        helperText={errors?.['email']?.message}
        label="Email Address"
        margin="normal"
        name="email"
        type="email"
        placeholder="Email"
        variant="outlined"
      />
      <TextField
        {...register('password')}
        error={Boolean(errors?.['password'])}
        fullWidth
        helperText={errors?.['password']?.message}
        label="Password"
        margin="normal"
        name="password"
        type="password"
        variant="outlined"
      />
      {invalidCredentials && (
        <Box mt={3}>
          <FormHelperText error>{invalidLoginText}.</FormHelperText>
        </Box>
      )}
      <Box mt={2}>
        <Button
          color="primary"
          disabled={formState.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Log In
        </Button>
      </Box>
      <Box mt={2}>
        <Alert severity="info">
          <div>Please enter your email address and password</div>
        </Alert>
      </Box>
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
};

export default LoginForm;
